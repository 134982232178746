import {convertObjectToClass} from "../util/ClassConvertor";
import {getPref, setPref} from "./userPreferences";
import {Preference} from "../class/preference/Preference";
const axios = require('axios')
const _ = require('lodash')
const moment = require('moment')

export class UserService {

  initialized: boolean = false
	apiUrl: string
	apiKey: string
	users: object[]
	userIdLookup: object
	userUpnLookup: object
	userMailLookup: object
	loginUser: any | undefined

	constructor(apiUrl: string, apiKey: string) {
		this.apiUrl = apiUrl
		this.apiKey = apiKey
		this.users = []
		this.userIdLookup = {}
		this.userUpnLookup = {}
		this.userMailLookup = {}
		this.loginUser = undefined
	}

	init = () => {
		//let url = `${this.apiUrl}bo_admin?transform=1&order[]=familienaam,asc&token=${this.apiKey}`;
		let url = `${this.apiUrl}bo_admin?filter[]=status,neq,archief&transform=1&order[]=familienaam,asc&token=${this.apiKey}`;

		return new Promise((resolve, reject) => {
				axios.get(url)
					.then(response => {
            			this.initialized = true
						this.users = response.data.bo_admin.map((rawUser: any) => convertObjectToClass(rawUser, 'User'));
						this.userIdLookup = _.keyBy(this.users, user => user.id)
						this.userUpnLookup = _.keyBy(this.users, user => user.upn)

						resolve(this.users)
					})
					.catch((error) => {
						console.log(error);
						reject(error)
					});
			}
		)

	}

	getServerCookies = () => {
		const url = "https://lib.arteveldehogeschool.be/mediatheken/cookieCall.php"

		return new Promise((resolve, reject) => {

			axios.get(url)
				.then(response => {
					resolve(response.data)
				})
				.catch((error) => {
					console.log(error);
					reject(error)
				});
			}
		)
	}

	increaseBackofficeVisitCount = (user) => {
		user.boc++
		user.lastLogin = moment().format("YYYY-MM-DD HH:mm:ss")
		let url = `${this.apiUrl}bo_admin/${user.id}?token=${this.apiKey}`
		axios.put(url, user)
			.then(() => {
			})
			.catch((error) => {
				console.log(error);
			});
	}

	saveUser = (user) => {
		//user kan een gecloned object zijn vanuit react state. Daarom ook in de lookup vervangen
		let url = `${this.apiUrl}bo_admin/${user.id}?token=${this.apiKey}`;

		return new Promise((resolve, reject) => {
				axios.put(url, user)
					.then(() => {
						this.users = this.users.map((u: any) => {
							if (u.id === user.id)
								return user
							return u
						})
						this.userIdLookup[user.id] = user
						this.userUpnLookup[user.upn] = user
						resolve({users: this.users})
					})
					.catch((error) => {
						console.log(error);
						reject(error)
					});
			}
		)
	}

	createUser = (user) => {
		return new Promise((resolve, reject) => {
				let url = `${this.apiUrl}bo_admin/?token=${this.apiKey}`
				axios.post(url, user)
					.then(response => {
						user.id = response.data
						this.users.push(user)
						this.userIdLookup[user.id] = user
						resolve({user})
					})
					.catch((error) => {
						console.log(error)
						reject(error)
					});
			}
		)
	}

	archiveerUser = (user) => {

		user.status = 'archief'

		return new Promise((resolve) => {
				this.saveUser(user).then(() => {
					this.users = this.users.filter((u: any) => u.id !== user.id)
					this.userIdLookup = _.keyBy(this.users, user => user.id)
					this.userUpnLookup = _.keyBy(this.users, user => user.upn)
					resolve({users: this.users})
				})

			}
		)
	}

	//Helper functions for preferences
	getLoginUserLastMediatheek = () => {
		if (!this.loginUser)
			return ''

		return new Promise((resolve) => {
			getPref(Preference.LAST_CAMPUS, this.loginUser.userName).then((pref: any) => {
				resolve(pref.prefValue)
			})
		})
	}

	setLoginUserLastMediatheek = (mediatheek: string): void => {
		setPref(Preference.LAST_CAMPUS, mediatheek, this.loginUser.userName, Preference.CONTEXT_ALGEMEEN)
	}
}
