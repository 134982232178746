import {AbstractZoekresultaat} from "../class/zoekresultaat/AbstractZoekresultaat";
import {ZoekQuery} from "../class/zoek/ZoekQuery";
import {ZoekresultaatDetail} from "../class/zoekresultaat/ZoekresultaatDetail";
import {ApplicationPath} from "../domain/path";
import {convertObjectToClass} from "../util/ClassConvertor";

const axios = require('axios')
const _ = require('lodash')

export class ZoekService {

    static readonly ZOEKSET_XML_URL: string = 'https://lib.arteveldehogeschool.be/mediatheken/root/zoekset/zoeksets.xml'
    static readonly ZOEKSET_JSON_URL: string = 'https://lib.arteveldehogeschool.be/mediatheken/root/zoekset/zoeksets.json'

    private readonly EPUB_TABLE: string = 'e_publicaties'
    private readonly BIDOC_KOPPELING_TABLE: string = 'bidocKoppeling'
    private readonly BEWAARDE_ZOEKOPDRACHTEN_TABLE: string = 'bewaardeZoekopdrachten'
    private readonly APA_TEST_TABLE: string = 'apa_test'

    apiKey: string
    apiUrl: string
    initialized: boolean = false
    zoeksets: any[]
    zoekresultaten: any[]
    zoekresultaatDetailLookup: object = {}
    databanken: any[]
    uitgelicht: any[]
    apaTests: any[]

    constructor(apiUrl, apiKey) {
        this.apiKey = apiKey
        this.apiUrl = apiUrl
    }


//==============================================================
//==============================================================
//                      ZOEK
//==============================================================
//==============================================================

    getZoekresultaat = (zoekQuery: ZoekQuery) => {

        return new Promise((resolve, reject) => {

            console.log('SEARCH QUERY', zoekQuery)
            axios.post(ApplicationPath.searchApiUrl, zoekQuery)
                .then(response => {

                    const wrapper = response.data
                    wrapper.zoekresultaten = wrapper.zoekresultaten ? wrapper.zoekresultaten.map(zr => convertObjectToClass(zr)) : []
                    console.log('SERVER SESPONSE', wrapper)
                    resolve(wrapper)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error)
                });
        })
    }

    getZoekresultaatDetail = (zoekresultaat: AbstractZoekresultaat) => {

        return new Promise((resolve, reject) => {

            //is alle detail info beschikbaar?
            if (zoekresultaat.isFull) {
                resolve(zoekresultaat)
                return
            }


            //is alle localeContentData beschikbaar?

            /*if (!this.zoekresultaatDetailLookup[zoekresultaat.type]) this.zoekresultaatDetailLookup[zoekresultaat.type] = {}
            const typeLookup = this.zoekresultaatDetailLookup[zoekresultaat.type]*/

            const zoekQuery = new ZoekQuery()
            zoekQuery.zoekresultaatDetail = ZoekresultaatDetail.DETAIL
            zoekQuery.zoekresultaat = {bron: zoekresultaat.bron, id: zoekresultaat.id} as AbstractZoekresultaat
            zoekQuery.upn = 'danydh@arteveldehs.be'

            axios.post(ApplicationPath.searchApiUrl, zoekQuery)
                .then(response => {
                    const detailObject = response.data

                    for (let prop in detailObject) {
                        zoekresultaat[prop] = detailObject[prop]
                    }

                    zoekresultaat.isFull = true
                    resolve(zoekresultaat)
                })
                .catch((error) => {
                    console.log('ERROR', error, zoekQuery, zoekresultaat)
                    reject(error)
                });
        })
    }

    getGebruikersgegevens = (zoekresultaat, arteveldeUser) => {
        return new Promise((resolve, reject) => {

            const zoekQuery = new ZoekQuery()
            zoekQuery.zoekresultaatDetail = 'getGebruikersgegevens'
            zoekQuery.zoekresultaat = zoekresultaat
            zoekQuery.upn = arteveldeUser.upn
            zoekQuery.userID = arteveldeUser.barcode

            axios.post(ApplicationPath.searchApiUrl, zoekQuery)
                .then(response => {
                    const detailObject = response.data

                    resolve(detailObject)
                })
                .catch((error) => {
                    console.log('ERROR', error, zoekQuery, zoekresultaat)
                    reject(error)
                });
        })
    }

    verstuurAanvraagActivatiecode = (epublicatie, arteveldeUser) => {
        return new Promise((resolve, reject) => {

            const postObject = {} as any
            postObject.function = 'verstuurAanvraagActivatiecode'
            postObject.epublicatie = epublicatie
            postObject.arteveldeUser = arteveldeUser

            axios.post(ApplicationPath.userApiUrl, postObject)
                .then(response => {
                    console.log('RESP', response)
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log('ERROR', error)
                    reject(error)
                });
        })
    }

    meldInlogProbleem = (epublicatie, arteveldeUser, probleemBeschrijving) => {
        return new Promise((resolve, reject) => {

            const postObject = {} as any
            postObject.function = 'meldInlogProbleem'
            postObject.epublicatie = epublicatie
            postObject.arteveldeUser = arteveldeUser
            postObject.probleemBeschrijving = probleemBeschrijving

            axios.post(ApplicationPath.userApiUrl, postObject)
                .then(response => {
                    console.log('RESP', response)
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log('ERROR', error)
                    reject(error)
                });
        })
    }

    /* getUitgelicht = () => {
         let me = this

         //SELECT * FROM `e_publicaties` WHERE `epub_type` LIKE '85' AND `uitgelicht` = 1

         return new Promise(function (resolve, reject) {

             if (me.uitgelicht){
                 resolve(me.uitgelicht)
                 return
             }

             Promise.all([me.getUitgelichteDatabanken(), me.getUitgelichteSpelen()]).then((res) => {
                 me.uitgelicht = _.concat(res[0], res[1])
                 resolve(me.uitgelicht)
             })

         });
     }*/

    getUitgelichteEpubs = () => {
        let me = this

        return new Promise(function (resolve, reject) {

            let url = `${me.apiUrl}${me.EPUB_TABLE}?filter[]=uitgelicht,eq,1&transform=1&token=${me.apiKey}`

            axios.get(url)
                .then(response => {
                    const rawdata = response.data[me.EPUB_TABLE].map(ul => convertObjectToClass(ul, 'ZoekresultaatEPub'))
                    resolve(rawdata)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                });

        });
    }

    /*getUitgelichteSpelen = () => {
        let me = this

        return new Promise(function (resolve, reject) {

            let url = `${me.apiUrl}${me.BIDOC_KOPPELING_TABLE}?filter[]=uitgelicht,eq,1&transform=1&token=${me.apiKey}`

            axios.get(url)
                .then(response => {
                    const rawdata = response.data[me.BIDOC_KOPPELING_TABLE].map(ul => convertObjectToClass(ul, 'BidocKoppeling'))
                    resolve(rawdata)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                });

        });
    }*/

//==============================================================
//==============================================================
//                      ZOEKSETS
//==============================================================
//==============================================================

    getZoeksets = () => {

        return new Promise((resolve, reject) => {
            axios.get(ZoekService.ZOEKSET_JSON_URL).then(response => {
                this.zoeksets = response.data
                resolve(this.zoeksets)
            }).catch(error => {
                console.log('ERROR', error)
                reject(error)
            })
        })
    }

    getDatabanken = () => {
        let me = this

        return new Promise(function (resolve, reject) {

            let url = `${me.apiUrl}${me.EPUB_TABLE}?filter[]=epub_type,eq,85&transform=1&token=${me.apiKey}`

            axios.get(url)
                .then(response => {

                    //me.databanken = response.data[me.EPUB_TABLE]
                    me.databanken = _.orderBy(response.data[me.EPUB_TABLE], ['uitgelicht', 'titel'], ['desc', 'asc'])
                    resolve({databanken: response.data[me.EPUB_TABLE]})
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                });

        });
    }

    getVerfijnResults = (query: string, locale: string = 'nl') => {

        return new Promise(function (resolve, reject) {
            const url = ApplicationPath.boServerZoekService + `?function=getVerfijnResultaten&query=${query}&locale=${locale}`
            axios.get(url)
                .then(response => {
                    const detailObject = response.data
                    resolve(detailObject)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error)
                });
        })
    }

    //==============================================================
    //==============================================================
    //                      ZOEKLIJSTEN
    //==============================================================
    //==============================================================

    getPubliekeZoeklijst = (zoeklijstId: number, arteveldeUser) => {

        const query = new ZoekQuery()
        query._explicitType = 'ZoekQuery'
        query.function = "getPubliekeZoeklijst";
        query.zoeklijstId = zoeklijstId
        query.userID = arteveldeUser.barcode

        return new Promise(function (resolve, reject) {
            const url = ApplicationPath.userApiUrl
            axios.post(url, query)
                .then(response => {
                    const detailObject = response.data
                    const zoeklijst = detailObject.zoeklijst
                    const zoekresultaten = detailObject.zoekresultaten.map(zr => convertObjectToClass(zr))
                    resolve({zoeklijst, zoekresultaten})
                })
                .catch((error) => {
                    console.log('ERROR', error);
                    reject(error)
                });
        })
    }


    //==============================================================
    //==============================================================
    //                      BEWAARDE ZOEKOPDRACHTEN
    //==============================================================
    //==============================================================

    getBewaardeZoekopdracht = (bewaardeZoekopdrachtId) => {

        let me = this

        return new Promise(function (resolve, reject) {

            let url = `${me.apiUrl}${me.BEWAARDE_ZOEKOPDRACHTEN_TABLE}/${bewaardeZoekopdrachtId}?transform=1&token=${me.apiKey}`

            axios.get(url)
                .then(response => {
                    resolve(convertObjectToClass(response.data))
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                });

        });
    }

    //==============================================================
    //==============================================================
    //                      APA
    //==============================================================
    //==============================================================

    getApaTests = () => {

        let me = this

        return new Promise((resolve, reject) => {


            const url = `${me.apiUrl}${me.APA_TEST_TABLE}?transform=1&token=${me.apiKey}`
            axios.get(url)
                .then(response => {
                    me.apaTests = response.data[me.APA_TEST_TABLE]
                    resolve(me.apaTests)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        })
    }

    updateApaTest = (apaTest) => {

        let me = this

        let url = `${me.apiUrl}${me.APA_TEST_TABLE}/${apaTest.id}?&token=${me.apiKey}`
        //console.log('URL', url)

        return new Promise((resolve, reject) => {
                axios.put(url, apaTest)
                    .then((result) => {
                        me.apaTests = me.apaTests.map(t => {
                            if (t.id === apaTest.id)
                                return apaTest;
                            return t;
                        })
                        resolve(me.apaTests)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    createApaTest = (apaTest) => {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.APA_TEST_TABLE}/?token=${me.apiKey}`
                axios.post(url, apaTest)
                    .then(response => {
                        apaTest.id = response.data;

                        me.apaTests.push(apaTest)
                        resolve(apaTest)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteApaTest = (apaTest) => {

        let me = this

        return new Promise((resolve, reject) => {
                const url = `${me.apiUrl}${me.APA_TEST_TABLE}/${apaTest.id}?token=${me.apiKey}`

                axios.delete(url)
                    .then(() => {
                        me.apaTests = me.apaTests.filter(at => at.id !== apaTest.id)
                        resolve(me.apaTests)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }
}
