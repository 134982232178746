import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {Image, Icon} from "semantic-ui-react"


const Parser = require('html-react-parser')

const MAX_BEZETTING_PERCENTAGE = 100

const MediatheekTile = ({mediatheek, selected, onClick, currentBalieMedewerker, mediatheekService, localeManager, config}) => {

    //const [openingsuren, setOpeningsuren] = useState()
    const [tileWidth, setTileWidth] = useState(200)
    const [aantalBezoekersInMediatheek, setAantalBezoekersInMediatheek] = useState()
    const [filter, setFilter] = useState()
    const [scale, setScale] = useState()
    const [mediatheekIsOpen, setMediatheekIsOpen] = useState(false)
    const [mediatheekClass, setMediatheekClass] = useState()
    const [fontSize, setFontSize] = useState()
    const [bezettingFontSize, setBezettingFontSize] = useState()
    const [bString, setBString] = useState('')
    const [opString, setOpString] = useState('')

    //const minuutTeller = mediatheekService.minuutTellersLookup[mediatheek.campusCode]




    const tileRef = useRef()

    useEffect(() => {

        const getFontSize = (string, mode) => {
            let factor
            switch (mode) {
                case 'openingsuren':
                    factor = 10
                    break
                case 'bezetting':
                    factor = 15
                    break
                default:
                    break
            }

            const fontSize = Math.min(tileWidth / factor, 20)

            return fontSize + 'px'
        }


        const openingsuren = mediatheekService.openingsurenLookup[mediatheek.campusCode]

        const minuutTeller = mediatheekService.minuutTellersLookup[mediatheek.campusCode]
        const aantalBezoekersInMediatheek = minuutTeller ? (minuutTeller.enters - minuutTeller.exits) : 0
        const bezettingsPercentage = aantalBezoekersInMediatheek > 0 ? aantalBezoekersInMediatheek / (mediatheek.maxBezetting + mediatheek.uitzondering) * MAX_BEZETTING_PERCENTAGE : 0
        const filter = !selected ? 'grayscale(70%) blur(2px)' : ''
        const scale = !selected ? 'scale(0.9)' : ''
        let mediatheekIsOpen = false, mediatheekClass
        openingsuren.forEach(op => mediatheekIsOpen = op.mediatheekIsOpen ? true : mediatheekIsOpen)
        let ops = openingsuren.join('<br />')
        const bs = aantalBezoekersInMediatheek + ' (' + bezettingsPercentage.toFixed(0) + '%)'


        switch (config.mediatheken.openingsurenStatus) {
            case "custom":
                mediatheekClass = 'mediatheek-custom'
                mediatheekIsOpen = false
                ops = config.mediatheken.openingsurenStatusOptions.custom.label
                break
            case "gesloten":
                mediatheekClass = 'mediatheek-gesloten'

                mediatheekIsOpen = false
                ops = 'Gesloten'
                break
            default: //live
                mediatheekClass = 'mediatheek-open'
                break
        }

        //setMinuutTeller(minuutTeller)
        //setOpeningsuren(openingsuren)
        setAantalBezoekersInMediatheek(aantalBezoekersInMediatheek)
        setFilter(filter)
        setScale(scale)
        setMediatheekIsOpen(mediatheekIsOpen)
        setMediatheekClass(mediatheekClass)
        setFontSize(getFontSize(openingsuren, 'openingsuren'))
        setBezettingFontSize(getFontSize(bs, 'bezetting'))
        setBString(bs)
        setOpString(ops)
    }, [mediatheek.campusCode, mediatheekService.openingsurenLookup, config.mediatheken.openingsurenStatus, config.mediatheken.openingsurenStatusOptions.custom.label, mediatheek.maxBezetting, mediatheek.uitzondering, mediatheekService.minuutTellersLookup, tileWidth, selected])

    useEffect(() => {
        const onResize = () => {
            if (!tileRef.current) return
            const newWidth = tileRef.current.offsetWidth
            if (newWidth !== tileWidth) {
                setTileWidth(newWidth)
            }
        }

        window.addEventListener('resize', onResize)
    }, [tileWidth])


    if (!mediatheek || !mediatheek.campusCode || !mediatheekService.minuutTellersLookup)
        return null

    return (
        <div style={{
            margin: '10px', filter,
            marginBottom: '-70px',
            transition: 'transform .4s',
            cursor: 'pointer',
            transform: scale
        }}
             ref={tileRef}
             onClick={() => onClick(mediatheek)}>
            <Image src={`//lib.arteveldehogeschool.be/mediatheken/root/mediatheek-assets/fotos/mediatheken/med-${mediatheek.campusCode}.jpg`} size='large'/>
            <div className={mediatheekClass} style={{
                minHeight: '70px',
                position: 'relative',
                bottom: '70px',
                color: 'white',
                fontFamily: 'Arial Black',
                fontSize, textAlign: 'right'
            }}>
                <div className='tile-info-box'>{Parser(localeManager.getString(opString))}</div>
                {currentBalieMedewerker && mediatheekIsOpen &&
                <div className='tile-info-box tile-info-box-medewerker'>
                    <Image className='wie-is-wie' src={currentBalieMedewerker.profielFotoUrl} size='mini'/>
                </div>
                }
                {(aantalBezoekersInMediatheek > 0 && mediatheekIsOpen) &&
                <div className='tile-info-box tile-info-box-bezetting' style={{fontSize: bezettingFontSize}}><Icon name='user'/>{bString}</div>
                }
            </div>
        </div>
    )
}

export default MediatheekTile

MediatheekTile.propTypes = {
    mediatheekService: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    mediatheek: PropTypes.object.isRequired,
    selected: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    currentRoosteringEvent: PropTypes.object,
    currentBalieMedewerker: PropTypes.object

}

MediatheekTile.defaultProps = {}