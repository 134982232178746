import React, {useState} from 'react'
import {Button, Segment} from 'semantic-ui-react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'

const {CopyToClipboard} = require('react-copy-to-clipboard')

const EbscolinkRenderer = ({zoekresultaat, localeManager, label, isKiosk}) => {

    const [linkCopied, setLinkCopied] = useState(false)

    if (!zoekresultaat.ebscoLink) return null
console.log('EBSCO', isKiosk)
    if (isKiosk) return null

    return (
        <AbstractZoekresultaatRenderer label={localeManager.getString(label)}>
            <Segment style={{backgroundColor: '#e4e4e4', display: 'flex', alignItems: 'center'}}>
                <a href={zoekresultaat.ebscoLink} target='_blank' rel='noopener noreferrer'>{zoekresultaat.ebscoLink}</a>
                <div style={{flexGrow: 1}}/>
                <CopyToClipboard text={zoekresultaat.ebscoLink}
                                 onCopy={() => setLinkCopied(true)}>
                    <Button circular color={linkCopied ? 'green' : 'grey'} icon={linkCopied ? 'check' : 'clipboard'}/>
                </CopyToClipboard>

            </Segment>

            <div><p>Indien u problemen ondervindt met deze link, raadpleeg dan dit <a href='https://lib.arteveldehogeschool.be/mediatheken/live/ws/faq/toegang-databanken-buiten-de-hogeschool'
                                                                                      rel="noopener noreferrer" target='_blank'>Faq artikel</a></p></div>
        </AbstractZoekresultaatRenderer>
    )
}

export default EbscolinkRenderer