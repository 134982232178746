//import {User} from "../class/user/User";
import {convertObjectToClass} from "../util/ClassConvertor";
import {Bijlage} from "../class/bijlage/Bijlage";
const _ = require('lodash')
const axios = require('axios')


export class BijlageService {

    private readonly BIJLAGE_TABLE: string = 'bijlagen'

    initialized: boolean = false
    bijlagen: Array<Bijlage>
    bijlageLookup: object
    apiUrl: string
    apiKey: string
    uploadUrl: string

    constructor(apiUrl: string, apiKey: string, uploadUrl: string) {
        this.apiUrl = apiUrl
        this.apiKey = apiKey
        this.uploadUrl = uploadUrl
        this.bijlagen = []
        this.bijlageLookup = {}
    }

    init = () => {

        let me = this

        return new Promise((resolve) => {

                this.getBijlagen().then((response: any) => {

                    me.initialized = true
                    me.bijlagen = response.bijlagen
                    me.bijlageLookup = response.bijlageLookup

                    resolve({status: 'ok'})
                }).catch((error) => {
                    console.log('ERROR IN Bijlage INIT', error)
                })
            }
        )

    }

    private getBijlagen() {

        let me = this

        return new Promise(function (resolve, reject) {
            let url = `${me.apiUrl}${me.BIJLAGE_TABLE}?transform=1&token=${me.apiKey}`
            axios.get(url)
                .then(response => {
                    let rawBijlageList = response.data[me.BIJLAGE_TABLE];
                    let bijlagen = rawBijlageList.map((rb: any) => convertObjectToClass(rb, 'Bijlage'))
                    bijlagen = _.orderBy(bijlagen, [bijlage => bijlage.label.toLowerCase()])
                    let bijlageLookup = _.keyBy(bijlagen, bijlage => bijlage.id)
                    resolve({bijlagen, bijlageLookup})
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    updateBijlage = (bijlage: Bijlage) => {

        const me = this
        const url = `${me.apiUrl}${me.BIJLAGE_TABLE}/${bijlage.id}?&token=${me.apiKey}`

        return new Promise((resolve, reject) => {
                axios.put(url, bijlage)
                    .then((_result) => {
                        //remap the bijlage
                        bijlage = convertObjectToClass(bijlage, 'Bijlage');
                        me.bijlagen = me.bijlagen.map(t => {
                            if (t.id === bijlage.id)
                                return bijlage
                            return t
                        })
                        me.bijlageLookup = _.keyBy(me.bijlagen, bijlage => bijlage.id)
                        resolve({bijlagen: me.bijlagen, bijlageLookup: me.bijlageLookup, bijlage})
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    });
            }
        )
    }

    createBijlage(bijlage: Bijlage) {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.BIJLAGE_TABLE}/?token=${me.apiKey}`
                axios.post(url, bijlage)
                    .then(response => {
                        bijlage.id = response.data;
                        me.bijlagen = _.concat(me.bijlagen, bijlage)
                        me.bijlagen = _.orderBy(me.bijlagen, [bijlage => bijlage.label.toLowerCase()])
                        me.bijlageLookup = _.keyBy(me.bijlagen, bijlage => bijlage.id)
                        resolve({bijlageLookup: me.bijlageLookup, bijlagen: me.bijlagen, bijlage})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteBijlage = (bijlage: Bijlage) => {

        const me = this
        const deleteBijlageUrl = 'https://lib.arteveldehogeschool.be/mediatheken/root/boDeleteImage.php'
        const deleteBijlageDetail = {fileUrl: `b_${bijlage.id}.${bijlage.ext}`, mode: 'bijlage'}

        return new Promise((resolve, reject) => {
            const url = `${me.apiUrl}${me.BIJLAGE_TABLE}/${bijlage.id}?token=${me.apiKey}`
                axios.delete(url)
                    .then(_response => {
                        me.bijlagen = _.filter(me.bijlagen, b => b.id !== bijlage.id);
                        me.bijlageLookup = _.keyBy(me.bijlagen, bijlage => bijlage.id);

                        console.log('DELETING BIJLAGE', deleteBijlageUrl, deleteBijlageDetail)
                        //we moeten niet enkel het record verwijderen maar ook de image indien die bestaat!
                        axios.post(deleteBijlageUrl, deleteBijlageDetail)
                            .then(response => {
                                console.log('DELETE RESPONSE', response)
                            });

                        resolve({bijlagen: me.bijlagen, bijlageLookup: me.bijlageLookup});
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }


    sendFileToServer = (file, bijlage: Bijlage, progressFunction) => {

        const formData = new FormData()
        formData.append('file', file)
        formData.append('filename', `b_${bijlage.id}`)
        formData.append('uploadMode', 'bijlage')

        const url = this.uploadUrl

        return new Promise((resolve, _reject) => {
                const config = {
                    onUploadProgress: progressFunction,
                    headers: {'Content-Type':'multipart/form-data'}
                }
                axios.post(url, formData, config)
                    .then(response => {
                        resolve(response)
                    })
            }
        )
    }
}
