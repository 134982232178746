import {Release} from "../class/admin/Release"
import {Changelog} from "../class/admin/Changelog"
import {TaxonomieItem} from '../class/taxonomie/TaxonomieItem'
import _ from 'lodash'
import {convertObjectToClass} from "../util/ClassConvertor"
import {releaseMail} from '../domain/releaseMailTemplate'
import {TaxonomieService} from "./TaxonomieService";
const axios = require('axios')
//const _ = require('lodash')




export class AdminService {

    static readonly BACKOFFICE: number = 217
    static readonly WEBSITE: number = 218
    static readonly REZERVEJO: number = 219
    static readonly ADA: number = 299
    static readonly COCOS: number = 300
    static readonly WEBSITE_VERSION_URL: string = 'https://lib.arteveldehogeschool.be/mediatheken/dev/ws/version.json'
    static readonly REZERVEJO_VERSION_URL: string = 'https://lib.arteveldehogeschool.be/mediatheken/reserveer/version.json'
    static readonly ADA_VERSION_URL: string = 'https://digitaalarchief.arteveldehogeschool.be/digitaalarchief/ada-desktop/version.json'
    private readonly ADMIN_RELEASES_TABLE: string = 'admin_releases'
    private readonly ADMIN_RELEASE_CHANGELOG_TABLE: string = 'admin_release_changelog'

    initialized: boolean = false
    releases: Release[]
    releaseLookup: object
    changelogs: Changelog[]
    changelogLookup: object
    software_release_ChangelogLookup: object
    apiUrl: string
    apiKey: string

    constructor(apiUrl: string, apiKey: string) {
        this.apiUrl = apiUrl
        this.apiKey = apiKey
        this.releases = []
        this.releaseLookup = {}
        this.changelogs = []
        this.changelogLookup = {}
        this.software_release_ChangelogLookup = {}
    }

    init = () => {

        let me = this


        //TODO: spread implementeren
        /*
        * function getUserAccount() {
      return axios.get('/user/12345')
    }
    function getUserPermissions() {
      return axios.get('/user/12345/permissions')
    }
    axios.all([getUserAccount(), getUserPermissions()])
      .then(axios.spread(function (acct, perms) {
        // Both requests are now complete
      }))
      */
        return new Promise((resolve) => {

                this.getReleases().then((response: any) => {
                    me.releases = response.releases
                    me.releaseLookup = response.releaseLookup
                    resolve({releases: me.releases})
                }).catch((error) => {
                    console.log('ERROR IN RELEASE INIT', error)
                })
            }
        )

    }

    private getReleases = () => {

        let me = this

        return new Promise(function (resolve, reject) {
            let url = `${me.apiUrl}${me.ADMIN_RELEASES_TABLE}?&transform=1&token=${me.apiKey}`
            axios.get(url)
                .then(response => {
                    let rawReleases = response.data[me.ADMIN_RELEASES_TABLE]
                    let releases = rawReleases.map((rr: any, index) => {
                        rr.key = index
                        return convertObjectToClass(rr, 'Release')
                    })
                    releases = _.orderBy(releases, ['released', 'datum'], ['asc', 'desc'])
                    let releaseLookup = _.keyBy(releases, release => release.id)
                    resolve({releases, releaseLookup})
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })

        })
    }

    getChangelogs = (softwareId: number, releaseId: number = 0) => {

        let me = this

        return new Promise(function (resolve, reject) {
            //cache
            if (me.software_release_ChangelogLookup[softwareId] && me.software_release_ChangelogLookup[softwareId][releaseId]) {
                resolve({changelogs: me.software_release_ChangelogLookup[softwareId][releaseId]})
                return
            }

            let url = `${me.apiUrl}${me.ADMIN_RELEASE_CHANGELOG_TABLE}?&filter[]=software,eq,${softwareId}&filter[]=release,eq,${releaseId}&transform=1&token=${me.apiKey}`
            axios.get(url)
                .then(response => {
                    let rawChangelogs = response.data[me.ADMIN_RELEASE_CHANGELOG_TABLE]
                    let changelogs = rawChangelogs.map((rc: object) => convertObjectToClass(rc, 'Changelog'))
                    me.changelogs = me.changelogs.concat(changelogs)
                    me.createLookups()
                    resolve({changelogs})
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })

        })
    }

    createHierarchicalChangelogWrappers = (changelogs: Changelog[], taxonomieService: TaxonomieService): any[] => {

        const dpo = _.groupBy(changelogs, 'module')
        let dataprovider: any[] = []
        for (var key in dpo) {
            dataprovider.push({key, label: taxonomieService.taxonomieItemLookup[key].label, children: dpo[key]})
        }

        return _.orderBy(dataprovider, ['label'])
    }

    getUnreleasedRelease = (softwareId: number) => {
        const releases = this.releases.filter(release => release.software === softwareId && release.released === 0)

        if (releases.length > 0) return releases[0]

        return null
    }

    getUnreleasedChangelogs = (softwareId: number) => {

        let me = this

        return new Promise(function (resolve, reject) {
            let url = `${me.apiUrl}${me.ADMIN_RELEASE_CHANGELOG_TABLE}?&filter[]=released,eq,0&filter[]=software,eq,${softwareId}&transform=1&token=${me.apiKey}`
            axios.get(url)
                .then(response => {
                    console.log('RESP', response, url)
                    let rawChangelogs = response.data[me.ADMIN_RELEASE_CHANGELOG_TABLE]
                    let changelogs = rawChangelogs.map((rc: object) => convertObjectToClass(rc, 'Changelog'))
                    resolve({unreleasedChangelogs: changelogs})
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })

        })
    }

    updateRelease(release: Release) {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.ADMIN_RELEASES_TABLE}/${release.id}?&token=${me.apiKey}`
                axios.put(url, release)
                    .then((response) => {
                        //remap the release
                        release = convertObjectToClass(release, 'Release')
                        me.releases = me.releases.map(t => {
                            if (t.id === release.id)
                                return release
                            return t
                        })
                        me.releases = _.orderBy(me.releases, ['released', 'datum'], ['asc', 'desc'])
                        me.releaseLookup = _.keyBy(me.releases, release => release.id)
                        resolve({releases: me.releases, releaseLookup: me.releaseLookup, response})
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    updateChangelogs(changelogs: any) {

        let me = this, idString: string

        if (Array.isArray(changelogs)) {
            idString = changelogs.map(changelog => changelog.id).join(',')
        } else {
            idString = changelogs.id
        }

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.ADMIN_RELEASE_CHANGELOG_TABLE}/${idString}?&token=${me.apiKey}`
                axios.put(url, changelogs)
                    .then(() => {
                        /*//remap the changelog
                                    changelog = convertObjectToClass(changelog, 'Changelog')

                                    //regenerate the changelogLookup
                                    me.changelogs = me.changelogs.map(cl => {
                                        if (cl.id === changelog.id)
                                            return changelog
                                        return cl
                                    })

                                    //replace changelog in changelogLookup
                                    me.changelogLookup[changelog.id] = changelog*/

                        resolve({changelogs})
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    createRelease(release: Release) {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.ADMIN_RELEASES_TABLE}/?token=${me.apiKey}`
                axios.post(url, release)
                    .then(response => {
                        release.id = response.data
                        me.releases = _.concat(me.releases, release)
                        me.releases = _.orderBy(me.releases, ['released', 'datum'], ['asc', 'desc'])
                        me.releaseLookup = _.keyBy(me.releases, release => release.id)
                        resolve({release})
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    createChangelog(changelog: Changelog) {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.ADMIN_RELEASE_CHANGELOG_TABLE}/?token=${me.apiKey}`
                axios.post(url, changelog)
                    .then(response => {
                        changelog.id = response.data
                        me.changelogLookup[changelog.id] = changelog
                        me.changelogs.push(changelog)
                        me.createLookups()
                        resolve({
                            changelog,
                            changelogs: me.software_release_ChangelogLookup[changelog.software][changelog.release]
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    deleteRelease(release) {

        let me = this

        //items:

        //const idString = _.map(me.releaseChangelogLookup[release.id], item => item.id).join(',')
        //const deleteUrl = `${me.apiUrl}${me.ADMIN_RELEASE_CHANGELOG_TABLE}/${idString}?token=${me.apiKey}`

        return new Promise((resolve, reject) => {
                const url = `${me.apiUrl}${me.ADMIN_RELEASES_TABLE}/${release.id}?token=${me.apiKey}`
                axios.delete(url)
                    .then(() => {

                        //remove release items from database
                        /*axios.delete(deleteUrl)
                                        .catch((error) => {
                                            console.log('ERROR DELETING CHANGELOG', error)
                                            reject(error)
                                        })*/

                        //remove from releases and lookup
                        me.releases = _.filter(me.releases, t => t.id !== release.id)
                        me.releaseLookup = _.keyBy(me.releases, release => release.id)

                        resolve({releases: me.releases, releaseLookup: me.releaseLookup})
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    deleteChangelog(changelog: Changelog) {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.ADMIN_RELEASE_CHANGELOG_TABLE}/${changelog.id}?token=${me.apiKey}`
                axios.delete(url)
                    .then(() => {

                        //_.pull(me.releaseChangelogLookup[changelog.release], changelog)
                        _.pull(me.changelogs, changelog)

                        delete(me.changelogLookup[changelog.id])
                        me.createLookups()
                        resolve({changelogLookup: me.changelogLookup, changelogs: me.changelogs})

                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    createLookups = () => {
        this.software_release_ChangelogLookup = {}
        for (let changelog of this.changelogs) {
            if (!this.software_release_ChangelogLookup[changelog.software]) this.software_release_ChangelogLookup[changelog.software] = {}
            if (!this.software_release_ChangelogLookup[changelog.software][changelog.release]) this.software_release_ChangelogLookup[changelog.software][changelog.release] = []
            this.software_release_ChangelogLookup[changelog.software][changelog.release].push(changelog)
        }
    }

    getSoftwareVersion = (software: number) => {

        return new Promise((resolve, reject) => {
            let url = ''
            switch (software) {
                case AdminService.BACKOFFICE:
                    break
                case AdminService.WEBSITE:
                    url = AdminService.WEBSITE_VERSION_URL
                    break
                case AdminService.ADA:
                    url = AdminService.ADA_VERSION_URL
                    break
                case AdminService.REZERVEJO:
                    url = AdminService.REZERVEJO_VERSION_URL
                    break
            }

            if (url !== '') {
                url += '?time=' + new Date().getMilliseconds()
                axios.get(url)
                    .then(response => {
                        resolve(response.data.version)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            } else {
                resolve('0.0.0')
            }
        })
    }

    sendReleaseMail = (mode: string, release: Release, software: TaxonomieItem, changelogWrappers: any[], loginUserMail: string) => {

        const url: string = '//lib.arteveldehogeschool.be/mediatheken/bo-server/service/mailService.php'
        let address
        switch (mode) {
            case 'mail':
                address = 'arteveldemediathecarissen@arteveldehs.be'
                break
            case 'test':
                address = loginUserMail
                break
        }

        let mailcontent = releaseMail({release, software, changelogWrappers})

        //er zit een rare komma tussen de list items!
        mailcontent = mailcontent.replace(/>,</g, '><')
        if (mode === 'preview') {
            return mailcontent
        }

        const params = new URLSearchParams()
        params.set('function', 'mailrelease')
        params.append('subject', `Nieuwe versie ${software.code} (v${release.versie})`)
        params.append('address', address)
        params.append('mailcontent', mailcontent)

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        console.log('PARAM', params, mode, release, software, changelogWrappers)
        axios.post(url, params, config)
            .then(response => {
                console.log('MAIL RESPONS', response)
            })
            .catch((error) => {
                console.log('ERROR', error)
            })

        return mailcontent
    }
}
