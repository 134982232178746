import {Chat, Chatline} from "../class/chat/Chat";
import axios from 'axios'
import {ApplicationPath} from "../domain/path";
import moment from 'moment'

const firebaseConfig = {
    apiKey: "AIzaSyAWyKoKWa6AsnfUImndtItc_C3uK-UV_ao",
    authDomain: "arteveldehs-mediatheken-web.firebaseapp.com",
    databaseURL: "https://arteveldehs-mediatheken-web.firebaseio.com",
    projectId: "arteveldehs-mediatheken-web",
    storageBucket: "arteveldehs-mediatheken-web.appspot.com",
    messagingSenderId: "368693984886",
    appId: "1:368693984886:web:cec25900e08a5919636957",
    measurementId: "G-MNTZS1J5MX"
};

//const VAPID = "BAIsgvPYa56G5fNiMKBETcDqHXY417vIx5pjHJLR5H0CBBXQdBGbpDZmZhuV_Z3wX9-K5yuBea5rLboaoVwwJx8"
const CHAT_STATUS_OPEN = 'open'
//const CHAT_STATUS_GESLOTEN = 'gesloten'


export const ChatService = (firebase) => {


    firebase.initializeApp(firebaseConfig)
    const db = firebase.firestore()
    const storageRef = firebase.storage().ref()

    const getChatConfig = () => {
        return new Promise((resolve) => {
            const url = ApplicationPath.mediatheekRoot + 'chat/config.json'
            axios.get(url).then(res => {
                resolve(res.data)
            })
        })
    }

    const toggleBubble = (chatline, icon, eigenaar) => {
        let bubble = chatline.data().bubble
        if (!bubble) bubble = {}
        if (bubble[eigenaar] === icon) {
            delete bubble[eigenaar]
        } else {
            bubble[eigenaar] = icon
        }

        chatline.ref.update({bubble})
    }

    const createChat = (eigenaar, arteveldeUser) => {

        return new Promise((resolve) => {
            const newChat = Chat()
            newChat.eigenaar = eigenaar
            newChat.voornaam = arteveldeUser.givenName
            newChat.status = CHAT_STATUS_OPEN
            newChat.isAnoniem = !arteveldeUser.loggedInd
            newChat.timestamp = firebase.firestore.FieldValue.serverTimestamp()
            db.collection('chats').add(newChat).then(res => {
                resolve(res)
            })
        })

    }

    const closeChat = (chat, chatlines, rating, userService, eigenaar, kopieNaarEigenaar) => {

        return new Promise((resolve) => {

            if (chatlines.length === 0) {
                db.doc('chats/' + chat.id).update({status: 'closed'})
                console.log('CHAT SLUITEN ZONDER CHATLINES', chat)
                resolve('Chat closed')
                return
            }


            //console.log('CLOSING', chat, chatlines, rating, userService, eigenaar, typeof eigenaar)

            let behandelaars = []

            //create transcript
            let transcript = 'Chat transcriptie\n'
            transcript += 'Auteur: ' + (typeof eigenaar === 'object' ? eigenaar.emailAddress : eigenaar) + '\n'
            transcript += 'Datum: ' + moment(chatlines[0].data().timestamp.seconds * 1000).format('DD/MM/YYYY HH:mmu') + '\n\n'

            transcript += 'Transcriptie:\n\n'

            for (const chatline of chatlines) {
                if (chatline.data().auteur !== '') transcript += `${chatline.data().auteur}: `

                if (chatline.data().type === 'faq') {
                    chatline.data().inhoud = `<a href="${ApplicationPath.linkWebportaal}/faq/${chatline.data().faqId}">${chatline.data().inhoud}</a>`
                }
                transcript += `${chatline.data().inhoud}\n`
                transcript += `\n\n`

                if (chatline.data().bron === 'bo') {
                    if (behandelaars.indexOf(chatline.data().auteur) < 0) behandelaars.push(chatline.data().auteur)
                }
            }

            transcript += `Beoordeling gesprek: ${rating > 0 ? rating + '/5' : 'geen beoordeling gegeven'}`

            console.log('CHAT TRANSCRIPT', transcript)

            //doorsturen naar userApi
            const trObject = {
                chatId: chat.id,
                behandelaars,
                eigenaar,
                transcriptie: transcript,
                kopieNaarEigenaar
            }
            const formObject = {function: 'mailChatTranscriptie', trObject}

            axios.post(ApplicationPath.userApiUrl, formObject).then(response => {
                console.log('SERVER RESPONSE', response)
                //resolve(response)
                //niet resolven want we zijn nog niet klaar
            })

            let batch = db.batch();

            //refs
            //const clRef = db.collection('chatlines').where('chat', '==', chat.id)
            const whosTypingRef = db.doc('chats/' + chat.id).collection('whosTyping')
            const chatRef = db.doc('chats/' + chat.id)

            whosTypingRef.get().then(snapshot => {
                for (const chatline of chatlines) {
                    batch.delete(chatline.ref)
                }
                snapshot.forEach(wt => batch.delete(wt.ref))
                batch.update(chatRef, {status: 'closed', rating: rating})
                batch.commit().then(() => resolve('DELETE SUCCEED')).catch(err => console.log('ERROR IN BATCH COMMIT', err))
            })


        })

    }

    const createChatline = (chat, chatline, statusMessage) => {

        //wanneer chatline null is wordt enkel de status aangemaakt
        return new Promise((resolve) => {
            let batch = db.batch();
            //const timestamp = new Date().getTime()

            if (statusMessage) {
                const statusLine = Chatline()
                statusLine.timestamp = firebase.firestore.FieldValue.serverTimestamp()
                statusLine.chat = chat.id
                statusLine.bron = 'status'
                statusMessage += ' ' + moment().format('DD/MM/YYYY HH:mm:ss')
                statusLine.inhoud = `---------- ${statusMessage} ----------`
                statusLine.order = 1
                let statusRef = db.collection('chatlines').doc();
                batch.set(statusRef, statusLine);
            }

            if (chatline) {
                //if (validURL(chatline.inhoud)) chatline.url = chatline.inhoud
                chatline.timestamp = firebase.firestore.FieldValue.serverTimestamp()
                chatline.order = 2
                const chatlineRef = db.collection('chatlines').doc()
                batch.set(chatlineRef, chatline);
            }

            batch.commit().then(res => {
                resolve(res)
            })

        })
    }

    const uploadFile = (chatline, fileRef, progressFunction) => {

        return new Promise((resolve => {
            //create unique file id
            const iName = fileRef.lastModified + '_' + fileRef.name

            const uploadTask = storageRef.child(iName).put(fileRef)

            uploadTask.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                progressFunction && progressFunction(progress)

                /*switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused')
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running')
                        break;
                }*/
            }, (error) => {
                console.log('UPLOAD ERROR', error)
            }, () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    chatline.type = fileRef.type
                    chatline.timestamp = firebase.firestore.FieldValue.serverTimestamp()
                    chatline.url = downloadURL
                    chatline.inhoud = iName
                    db.collection('chatlines').add(chatline).then(res => {
                        //console.log('CHATLINE CREATED')
                    })
                    resolve(downloadURL)
                })
            })


        }))
    }

    const getRelatedFaqs = (chatlineString) => {
        //https://lib.arteveldehogeschool.be/mediatheken/dev/faq/faqe.php

        return new Promise((resolve) => {
            const url = "https://lib.arteveldehogeschool.be/mediatheken/dev/faq/faqe.php"
            const postObject = {string: chatlineString}
            //console.log('SERVER CALL', url, postObject)
            axios.post(url, postObject).then(res => {
                resolve(res.data)
            })
        })
    }

    /*const validURL = (str) => {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }*/

    return {
        db, createChat, closeChat, createChatline, getChatConfig, getRelatedFaqs, uploadFile, storageRef, toggleBubble
    }
}