import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import Parser, {domToReact} from "html-react-parser";
import {Button, Header, Icon, Label, Segment} from "semantic-ui-react";
import {DirectLink} from 'med-class'
const {CopyToClipboard} = require('react-copy-to-clipboard')

const QuestionRenderer = ({faq, localeContent, localeDataService, localeManager, onTagClick, answerVisible, enableLinks}) => {

    const [localAnswerVisible, setLocalAnswerVisible] = useState(answerVisible)
    const [answer, setAnswer] = useState('')
    const [linkCopied, setLinkCopied] = useState(false)

    useEffect(() => {

        if (answerVisible){
            localeDataService.getLocaleData(faq.id).then(() => {
                setAnswer(localeDataService.substitute(localeDataService.getPropertyValue(faq.id, 'answer', localeManager.locale)))
                setLocalAnswerVisible(answerVisible)
            })
        } else {
            setLocalAnswerVisible(answerVisible)
        }

    }, [answerVisible, localeManager])

    const url = 'https://lib.arteveldehogeschool.be/mediatheken/live/ws/'
    const directeLink = url + "faq/" + faq.restfulLink

    const toggleAnswer = () => {
        localeDataService.getLocaleData(faq.id).then(() => {
            setLocalAnswerVisible(!localAnswerVisible)
            setAnswer(localeDataService.substitute(localeDataService.getPropertyValue(faq.id, 'answer', localeManager.locale)))
        })

    }

    const clickTagHandler = (event, {name}) => {
        onTagClick && onTagClick(name)
    }

    return (
        <Segment>

            {!localAnswerVisible &&
            <div className='question-renderer' onClick={toggleAnswer}>{Parser(localeContent.content)}</div>
            }
            {localAnswerVisible &&
            <Fragment>
                <Header as='h2' style={{marginBottom: '20px'}} onClick={toggleAnswer}>{Parser(localeContent.content)}</Header>
                <div>
                    {Parser(answer, {replace: ({attribs, children}) => !enableLinks && attribs && attribs.href && <strong>{domToReact(children)}</strong>})}
                </div>
                {faq.tags !== '' && <div className='faq-tags'>
                    {faq.tags.split(',').map((tag, index) => {
                        return (
                            <Label name={tag} as='a' key={index} onClick={clickTagHandler}>
                                <Icon name='tag'/>{tag}
                            </Label>
                        )
                    })}
                </div>}

                {enableLinks && <div className='faq-directe-link'>
                    <span style={{marginRight: '10px'}}>{localeManager.getString('DIRECTE_LINK')}: </span>
                    <a href={directeLink} rel="noopener noreferrer" target='_blank'>{directeLink}</a>
                    <div style={{flexGrow: 1}}/>
                    <CopyToClipboard text={directeLink}
                                     onCopy={() => setLinkCopied(true)}>
                        <Button circular color={linkCopied ? 'green' : 'grey'} icon={linkCopied ? 'check' : 'clipboard'}/>
                    </CopyToClipboard>
                </div>}
            </Fragment>}
        </Segment>
    )
}

export default  QuestionRenderer


QuestionRenderer.propTypes = {
    faq: PropTypes.object.isRequired,
    localeContent: PropTypes.object.isRequired,
    localeDataService: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    onTagClick: PropTypes.func,
    answerVisible: PropTypes.bool,
    enableLinks: PropTypes.bool
}

QuestionRenderer.defaultProps = {
    answerVisible: false,
    enableLinks: true
}