import React, {useState} from 'react'
import {Button, Segment, Form, Modal, TextArea} from 'semantic-ui-react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'

const Parser = require('html-react-parser')

const GebruikersgegevensRenderer = ({zoekresultaat, localeManager, arteveldeUser, zoekService, onCampus}) => {

    const [dataVisible, setDataVisible] = useState(false)
    const [gebruikersgegevens, setGebruikersgegevens] = useState(null)
    const [modalOpen, toggleModalOpen] = useState(false)
    const [problemModalOpen, toggleProblemModalOpen] = useState(false)
    const [probleemBeschrijving, setProbleemBeschrijving] = useState('')
    const [probleemVerzonden, setProbleemVerzonden] = useState(false)

    //if (!zoekresultaat.gebruikersnaam) return null


    if (!gebruikersgegevens) {
        zoekService.getGebruikersgegevens(zoekresultaat, arteveldeUser).then(gg => setGebruikersgegevens(gg))
    }

    if (!gebruikersgegevens) return null

    if (!gebruikersgegevens.gebruikersnaam && !gebruikersgegevens.paswoord) return null

    const label = 'INLOGGEGEVENS'

    const showData = () => {
        setTimeout(() => setDataVisible(false), 20000)
        setDataVisible(true)
    }

    const verstuurAanvraag = () => {
        toggleModalOpen(false)
        const msgArr = localeManager.getString('ACTIVATIECODE_BEVESTIGING').split('||')
        zoekService.verstuurAanvraagActivatiecode(zoekresultaat, arteveldeUser).then(resp => {
            if (resp) {
                window.alert(msgArr[0])
            } else {
                window.alert(msgArr[1])
            }
        })
    }

    const onModalClose = () => {
        toggleModalOpen(false)
        toggleProblemModalOpen(false)
    }

    const informLicentieBeheerder = () => {
        toggleProblemModalOpen(true)
    }

    const verstuurProbleemMelding = () => {
        toggleProblemModalOpen(false)
        setProbleemVerzonden(true)

        zoekService.meldInlogProbleem(zoekresultaat, arteveldeUser, probleemBeschrijving).then(resp => {
            window.alert('Uw probleemmelding werd doorgestuurd!')
        })
    }

    return (
        <AbstractZoekresultaatRenderer label={localeManager.getString(label)}>
            {!arteveldeUser.loggedIn &&
            <div>{localeManager.getString('LOG_IN_VIA_MIJN_MEDIATHEEK')}</div>
            }

            {(arteveldeUser.loggedIn && zoekresultaat.toon_toegang_platform_button === 0) &&
            <Segment style={{backgroundColor: '#e4e4e4'}}>
                {dataVisible && <Form>
                    {gebruikersgegevens.gebruikersnaam && <Form.Field>
                        <label>{localeManager.getString('GEBRUIKERSNAAM')}</label>
                        <p>{gebruikersgegevens.gebruikersnaam}</p>
                    </Form.Field>}
                    {gebruikersgegevens.paswoord && <Form.Field>
                        <label>{localeManager.getString('PASWOORD')}</label>
                        <p>{gebruikersgegevens.paswoord}</p>
                    </Form.Field>}

                   {/* {!onCampus && <Segment>
                        Let op! U bent &nbsp;
                        <Label horizontal color='red'>
                            off campus
                        </Label>
                        Lees aandachtig <a href='https://lib.arteveldehogeschool.be/mediatheken/live/ws/faq/toegang-databanken-buiten-de-hogeschool' target='_blank'>deze faq</a> om thuistoegang te activeren.
                    </Segment>}*/}

                    <p>{localeManager.getString('PROBLEEM_LOGIN_DATA_TEKST')}</p>

                    {!probleemVerzonden &&
                    <Form.Button color='blue' content={localeManager.getString('PROBLEEM_LOGIN_DATA_BUTTON_LABEL')} onClick={informLicentieBeheerder}/>
                    }

                    {probleemVerzonden && <p>U probleem melding werd verstuurd.</p>}
                </Form>}
                {!dataVisible && <Button color='grey' onClick={showData}>{localeManager.getString('TOON')}</Button>}
            </Segment>
            }

            {(arteveldeUser.loggedIn && zoekresultaat.toon_toegang_platform_button === 1) &&
            <Segment style={{backgroundColor: '#e4e4e4'}}>
                <div style={{marginBottom: '10px'}}>{Parser(localeManager.getString('ACTIVATIECODE_UITLEG'))}</div>
                <Button color='green' onClick={() => toggleModalOpen(true)}>{localeManager.getString('VRAAG_ACTIVATIE_CODE')}</Button>
            </Segment>
            }

            <Modal dimmer='inverted' open={modalOpen} onClose={onModalClose} closeIcon>
                <Modal.Header>Activatiecode aanvragen</Modal.Header>

                <Modal.Content>
                    <div>Wanneer u bevestigt, dan zal één van onze medewerkers een activatiecode aanvragen op naam van:</div>
                    <p><strong>{arteveldeUser.displayName} ({arteveldeUser.emailAddress})</strong></p>
                    <p>Van zodra we beschikken over de activatiecode zal u een mail ontvangen.</p>
                    <p><strong>Let op:</strong> De activatiecode is enkel voor u geldig. Geef ze dus nooit door aan anderen.b</p>
                </Modal.Content>

                <Modal.Actions>
                    <Button onClick={onModalClose}>Annuleer</Button>
                    <Button color='green' onClick={verstuurAanvraag}>Verstuur aanvraag</Button>
                </Modal.Actions>
            </Modal>

            <Modal dimmer='inverted' open={problemModalOpen} onClose={onModalClose} closeIcon>
                <Modal.Header>Login probleem melden</Modal.Header>

                <Modal.Content>
                    <p>Beschrijf waarom u problemen ondervindt met het inloggen</p>
                    <TextArea style={{width: '100%', height: '200px'}} value={probleemBeschrijving} onChange={(event, {value}) => setProbleemBeschrijving(value)}/>
                </Modal.Content>

                <Modal.Actions>
                    <Button onClick={onModalClose}>Annuleer</Button>
                    <Button color='green' disabled={probleemBeschrijving.length < 20} onClick={verstuurProbleemMelding}>Verstuur</Button>
                </Modal.Actions>
            </Modal>
        </AbstractZoekresultaatRenderer>
    )
}

export default GebruikersgegevensRenderer