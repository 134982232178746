import {ApplicationPath} from "../domain/path";
import {convertObjectToClass} from "../util/ClassConvertor";
import {Stageverslag} from "../class/stageverslag/Stageverslag";
const _ = require('lodash')
const axios = require('axios')


export class StageverslagenService {

    private readonly STAGEVERSLAGEN_TABLE: string = 'stageverslagen'

    initialized: boolean = false
    opleidingen: object = {}
    opleidingFiltersLookup: object = {nl: {}, en: {}}
    stageverslagen: Array<Stageverslag>
    stageverslagenLookup: object
    stageverslagenSearchStringLookup: object
    apiUrl: string
    apiKey: string

    constructor(apiUrl: string, apiKey: string) {
        this.apiUrl = apiUrl
        this.apiKey = apiKey
        this.stageverslagen = []
        this.stageverslagenLookup = {}
        this.stageverslagenSearchStringLookup = {}
    }

    private getDistinctValues = (veld: string, filterKey?: string | undefined, filterValue?: string | undefined) => {

        return new Promise(function (resolve, reject) {

            const queryObject = {
                function: 'getDistinctValues',
                veld,
                filter: {key: filterKey, value: filterValue}
            }

            //console.log('QUERY', queryObject)
            axios.post(ApplicationPath.stageverslagenApiUrl, queryObject)
                .then(response => {
                    //console.log('RESPONSE', response)
                    if (Array.isArray(response.data)) {
                        const dataprovider = response.data.map((value, key) => {
                            return {key, text: value, value}
                        })
                        //console.log('RESULT DISTINCT', dataprovider)
                        resolve(dataprovider)
                    } else {
                        resolve([])
                    }
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    getOpleidingen = (locale: string) => {
        let me = this

        return new Promise((resolve) => {
            if (me.opleidingen[locale]) {
                console.log('CACHE')
                resolve(me.opleidingen[locale])
                return
            }

                this.getDistinctValues('opleiding_' + locale).then((result: any) => {
                    me.opleidingen[locale] = result
                    resolve({status: 'ok', result})
                }).catch((error) => {
                    console.log('ERROR IN Stageverslagen INIT', error)
                })
            }
        )
    }

    getOpleidingFilters = (opleiding: string, locale: string) => {

        let me = this
        return new Promise(function (resolve, reject) {

            if (me.opleidingFiltersLookup[locale][opleiding]) {
                console.log('CACHE')
                resolve(me.opleidingFiltersLookup[locale][opleiding])
                return
            }

            Promise.all([
                me.getDistinctValues('academiejaar', 'opleiding_' + locale, opleiding),
                me.getDistinctValues('optie_' + locale, 'opleiding_' + locale, opleiding),
                me.getDistinctValues('stageplaats_naam', 'opleiding_' + locale, opleiding),
                me.getDistinctValues('stageplaats_gemeente', 'opleiding_' + locale, opleiding),
                me.getDistinctValues('stageplaats_land', 'opleiding_' + locale, opleiding),
                me.getDistinctValues('taal_' + locale, 'opleiding_' + locale, opleiding),
            ]).then(values => {

                const filterObject = {
                    academiejaar: _.orderBy(values[0], ['value'], ['desc']),
                    optie: values[1],
                    stageplaats_naam: values[2],
                    stageplaats_gemeente: values[3],
                    stageplaats_land: values[4],
                    taal: values[5],
                }
                me.opleidingFiltersLookup[locale][opleiding] = filterObject
                resolve(filterObject)
            })
                .catch((error) => {
                console.log(error);
                reject(error);
            })
        })
    }

    private getStageverslagen(filterObject: Stageverslag, locale: string) {

        let me = this

        return new Promise(function (resolve, reject) {

            const opleiding = filterObject['opleiding_' + locale]

            if (!me.stageverslagenLookup[opleiding]) me.stageverslagenLookup[opleiding] = {}

            /*if (me.stageverslagenLookup[opleiding][filterObject.academiejaar]) {
                resolve(me.stageverslagenLookup[opleiding][filterObject.academiejaar])
                return
            }*/


            let url = `${me.apiUrl}${me.STAGEVERSLAGEN_TABLE}?transform=1&token=${me.apiKey}`
            //opleiding en academiejaar MOETEN in het filter object zitten!
            url += `&filter[]=opleiding_${locale},eq,${opleiding}`
            url += `&filter[]=academiejaar,eq,${filterObject.academiejaar}`
            if (filterObject['optie_' + locale]) url += `&filter[]=optie_${locale},eq,${filterObject['optie_' + locale]}`
            if (filterObject.stageplaats_naam) url += `&filter[]=stageplaats_naam,eq,${filterObject.stageplaats_naam}`
            if (filterObject.stageplaats_gemeente) url += `&filter[]=stageplaats_gemeente,eq,${filterObject.stageplaats_gemeente}`
            if (filterObject.stageplaats_naam) url += `&filter[]=stageplaats_naam,eq,${filterObject.stageplaats_naam}`
            if (filterObject['taal_' + locale]) url += `&filter[]=taal_${locale},eq,${filterObject['taal_' + locale]}`


            axios.get(url)
                .then(response => {
                    let rawStageverslagen = response.data[me.STAGEVERSLAGEN_TABLE];
                    let stageverslagen = rawStageverslagen.map((rt: any) => convertObjectToClass(rt, 'Stageverslag'))
                    stageverslagen = _.orderBy(stageverslagen, [stageverslag => stageverslag.student_familienaam.toLowerCase()])
                    me.stageverslagenLookup[opleiding][filterObject.academiejaar] = stageverslagen
                    //me.generateStageverslagenSearchStringLookup()
                    resolve(stageverslagen)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    /*updateFaq(stageverslag) {

        let me = this

        let url = `${me.apiUrl}${me.STAGEVERSLAGEN_TABLE}/${stageverslag.id}?&token=${me.apiKey}`
        console.log('URL', url)

        return new Promise((resolve, reject) => {
                axios.put(url, stageverslag)
                    .then((result) => {
                        //remap the stageverslag
                        console.log('SAVED', result, stageverslag)
                        stageverslag = convertObjectToClass(stageverslag, 'Faq')
                        me.stageverslagen = me.stageverslagen.map(t => {
                            if (t.id === stageverslag.id)
                                return stageverslag;
                            return t;
                        })
                        me.stageverslagenLookup = _.keyBy(me.stageverslagen, stageverslag => stageverslag.id)
                        resolve({stageverslagen: me.stageverslagen, stageverslagenLookup: me.stageverslagenLookup})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }*/

    /*createFaq(stageverslag) {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.STAGEVERSLAGEN_TABLE}/?token=${me.apiKey}`
                axios.post(url, stageverslag)
                    .then(response => {
                        stageverslag.id = response.data;
                        me.stageverslagen = _.concat(me.stageverslagen, stageverslag)
                        me.stageverslagen = _.orderBy(me.stageverslagen, [stageverslag => stageverslag.question.toLowerCase()])
                        me.stageverslagenLookup = _.keyBy(me.stageverslagen, stageverslag => stageverslag.id)
                        resolve({stageverslagenLookup: me.stageverslagenLookup, stageverslagen: me.stageverslagen, stageverslag})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }*/

    /*deleteFaq(stageverslag) {

        let me = this

        //let deleteImageUrl = 'https://lib.arteveldehogeschool.be/mediatheken/root/boDeleteImage.php'
        //let deleteImageDetail = {fileUrl: stageverslag.link, mode: 'faqImage'}

        return new Promise((resolve, reject) => {
                const url = `${me.apiUrl}${me.STAGEVERSLAGEN_TABLE}/${stageverslag.id}?token=${me.apiKey}`

                axios.delete(url)
                    .then(() => {

                        //remove from stageverslagen and lookup
                        me.stageverslagen = _.filter(me.stageverslagen, t => t.id !== stageverslag.id)
                        me.stageverslagenLookup = _.keyBy(me.stageverslagen, stageverslag => stageverslag.id)

                        /!*axios.post(deleteImageUrl, deleteImageDetail)
                          .then(response => {
                            console.log('DELETE RESPONSE', response)
                          });*!/

                        resolve({stageverslagen: me.stageverslagen, stageverslagenLookup: me.stageverslagenLookup})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }*/


    generateStageverslagenSearchStringLookup = () => {
        _.each(this.stageverslagen, stageverslag => {

            let lookupString = stageverslag.lookupString

            this.stageverslagenSearchStringLookup[stageverslag.id] = lookupString.toLowerCase()
        })
    }
}
