import {AbstractZoekresultaat} from "./AbstractZoekresultaat";
import {EPublicatie} from "../epub/EPublicatie";

export class ZoekresultaatEPub extends AbstractZoekresultaat {

    epublicatie: EPublicatie

    constructor(epublicatie: EPublicatie){
        super()
        this.epublicatie = epublicatie
        this.needLocaleContent = true
        for (var prop in epublicatie) {
            this[prop] = epublicatie[prop]
        }
    }

    _explicitType: string = "ZoekresultaatEPub"

    get datumAanmaak(){
        return this.epublicatie.datum_aanmaak
    }
    get datumWijziging(){
        return this.epublicatie.datum_wijziging
    }

    get bron(): string {
        return 'epub'
    }
    set bron(_value){
        //do nothing
    }

    get coverLink(){
        return '//lib.arteveldehogeschool.be/mediatheken/root/covers/epub/full/' + this.id + '_cover.jpg'
    }
    set coverLink(_value){
        //do nothing
    }

    get coverLinkSmall(){
        return '//lib.arteveldehogeschool.be/mediatheken/root/covers/epub/full/' + this.id + '_cover.jpg'
    }
    set coverLinkSmall(_value){
        //do nothing
    }


    getProps(){
        return [
            "titel",
            "subtitel",
            "auteurs",
            "uitgever",
            "publicatietype",
            "hoofdtitel",
            "groep",
            "reeks",
            "periode",
            "mediatheek",
            //"trefwoorden",
            "beschrijving",
            "opleiding",
            "link",
            "opmerking",
            "gebruikersnaam",
            "wachtwoord",
            "paswoord",
            "directe_Link",
            "apa"];
    }
}
