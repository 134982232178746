import {BidocKoppeling} from "../class/bidoc/BidocKoppeling";
import {convertObjectToClass} from "../util/ClassConvertor";
import {ZoekresultaatSpelwijzer} from "../class/zoekresultaat/ZoekresultaatSpelwijzer";

const axios = require('axios')
const _ = require('lodash')

interface Spel {
    titelid: number
    auteur: string
    titel: string
    materiaal: string
    jaar: string
    koppeling: string
    minSpelers: number
    maxSpelers: number
}

export class SpelwijzerService {

    private readonly BIDOC_KOPPELING_TABLE: string = 'bidocKoppeling'
    private readonly SPELWIJZER_WERKVORM_TABLE: string = 'spelwijzerWerkvorm'

    initialized: boolean = false
    apiUrl: string
    apiKey: string
    spelen: Spel[]
    spelenLookup: object
    bidocKoppelingen: BidocKoppeling[]
    bidocKoppelingLookup: object

    zoekresultatenBaseData: ZoekresultaatSpelwijzer[]
    zoekresultatenBaseDataLookup: object

    spelwijzerWerkvormLookup: object

    constructor(apiUrl: string, apiKey: string) {
        this.apiUrl = apiUrl
        this.apiKey = apiKey
        this.spelen = []
        this.spelenLookup = {}
        this.bidocKoppelingen = []
        this.bidocKoppelingLookup = {}
        this.zoekresultatenBaseData = []
        this.zoekresultatenBaseDataLookup = {}
        this.spelwijzerWerkvormLookup = {}
    }

    init = () => {
        let me = this

        return new Promise((resolve) => {

                if (me.initialized) {
                    resolve({spelen: me.spelen})
                    return
                }

                //me.initialized = true //tricky :)

                Promise.all([this.getSpelen(), this.getBidocKoppelingen(), this.getSpelwijzerWerkvormen()]).then(response => {
                    me.spelen = (response[0] as any).spelen
                    me.spelenLookup = _.keyBy(me.spelen, 'titelid')

                    me.bidocKoppelingen = (response[1] as any).bidocKoppelingen
                    me.bidocKoppelingLookup = _.keyBy(me.bidocKoppelingen, 'bidocId')
                    me.zoekresultatenBaseData = []
                    me.bidocKoppelingen.forEach(bk => {
                        if (me.spelenLookup[bk.bidocId]) {
                            me.zoekresultatenBaseData.push(new ZoekresultaatSpelwijzer(bk, me.spelenLookup[bk.bidocId]))
                        }
                    })
                    me.zoekresultatenBaseDataLookup = _.keyBy(me.zoekresultatenBaseData, 'titelid')

                    me.initialized = true
                    resolve({spelen: me.spelen})
                })
            }
        )

    }

    //==============================================================
    //==============================================================
    //                      SPELEN
    //==============================================================
    //==============================================================

    private getSpelen = () => {
        let url = `//lib.arteveldehogeschool.be/mediatheken/root/spelwijzer.json`

        return new Promise(function (resolve, reject) {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            axios.get(url, config)
                .then(response => {
                    const spelen = response.data
                    resolve({spelen})
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });

        });
    }

    //==============================================================
    //==============================================================
    //                      BIDOC KOPPELING
    //==============================================================
    //==============================================================

    private getBidocKoppelingen = () => {

        let me = this

        return new Promise(function (resolve, reject) {

            let url = `${me.apiUrl}${me.BIDOC_KOPPELING_TABLE}?&transform=1&token=${me.apiKey}`

            axios.get(url)
                .then(response => {
                    resolve({bidocKoppelingen: response.data.bidocKoppeling})
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                });

        });
    }

    getBidocKoppeling = (spel: Spel) => {

        let me = this

        return new Promise(function (resolve, reject) {

            if (me.bidocKoppelingLookup[spel.titelid]) {
                console.log('GET FROM CACHE')
                resolve(me.bidocKoppelingLookup[spel.titelid])
                return
            }

            let url = `${me.apiUrl}${me.BIDOC_KOPPELING_TABLE}?&transform=1&filter[]=bidocId,eq,${spel.titelid}&token=${me.apiKey}`

            axios.get(url)
                .then(response => {
                    const kopp = response.data.bidocKoppeling //dit is altijd een array
                    me.bidocKoppelingLookup[spel.titelid] = kopp.length > 0 ? convertObjectToClass(kopp[0], "BidocKoppeling") : new BidocKoppeling(spel.titelid)

                    resolve(me.bidocKoppelingLookup[spel.titelid])
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                });

        });
    }

    saveBidocKoppeling = (bidocKoppeling: BidocKoppeling) => {

        let me = this

        return new Promise(function (resolve, reject) {
            if (bidocKoppeling.id) {
                const url = `${me.apiUrl}${me.BIDOC_KOPPELING_TABLE}/${bidocKoppeling.id}?token=${me.apiKey}`
                axios.put(url, bidocKoppeling)
                    .then(response => {
                        if (bidocKoppeling.bidocId)
                            me.bidocKoppelingLookup[bidocKoppeling.bidocId] = bidocKoppeling
                        console.log('SAVED', bidocKoppeling, response)
                        resolve(bidocKoppeling)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    });
            } else {
                let url = `${me.apiUrl}${me.BIDOC_KOPPELING_TABLE}?token=${me.apiKey}`
                axios.post(url, bidocKoppeling)
                    .then(response => {
                        bidocKoppeling.id = response.data

                        if (bidocKoppeling.bidocId)
                            me.bidocKoppelingLookup[bidocKoppeling.bidocId] = bidocKoppeling
                        console.log('CREATED', bidocKoppeling)
                        resolve(bidocKoppeling)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        });
    }

    //==============================================================
    //==============================================================
    //                      SPELWIJZER WERKVORM
    //==============================================================
    //==============================================================

    getSpelwijzerWerkvormen = (bidocKoppeling?: BidocKoppeling) => {

        let me = this

        return new Promise(function (resolve, reject) {

            if (bidocKoppeling && me.spelwijzerWerkvormLookup[bidocKoppeling.id]) {
                resolve(me.spelwijzerWerkvormLookup[bidocKoppeling.id])
                return
            }

            const filter = bidocKoppeling ? `&filter[]=bidocKoppeling,eq,${bidocKoppeling.id}` : ''

            let url = `${me.apiUrl}${me.SPELWIJZER_WERKVORM_TABLE}?&transform=1${filter}&token=${me.apiKey}`

            axios.get(url)
                .then(response => {
                    for (const werkvorm of response.data.spelwijzerWerkvorm) {
                        if (!me.spelwijzerWerkvormLookup[werkvorm.bidocKoppeling]) me.spelwijzerWerkvormLookup[werkvorm.bidocKoppeling] = []
                        me.spelwijzerWerkvormLookup[werkvorm.bidocKoppeling].push(werkvorm)
                    }

                    resolve(bidocKoppeling ? me.spelwijzerWerkvormLookup[bidocKoppeling.id] : me.spelwijzerWerkvormLookup)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                });

        });
    }

    updateSpelwijzerWerkvorm = (spelwijzerWerkvorm) => {

        let me = this

        let url = `${me.apiUrl}${me.SPELWIJZER_WERKVORM_TABLE}/${spelwijzerWerkvorm.id}?&token=${me.apiKey}`

        return new Promise((resolve, reject) => {
                axios.put(url, spelwijzerWerkvorm)
                    .then((result) => {
                        me.spelwijzerWerkvormLookup[spelwijzerWerkvorm.bidocKoppeling] = me.spelwijzerWerkvormLookup[spelwijzerWerkvorm.bidocKoppeling].map(wv => {
                            if (wv.id === spelwijzerWerkvorm.id) return spelwijzerWerkvorm
                            return wv
                        })

                        resolve(me.spelwijzerWerkvormLookup[spelwijzerWerkvorm.bidocKoppeling])
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    createSpelwijzerWerkvorm = (spelwijzerWerkvorm) => {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.SPELWIJZER_WERKVORM_TABLE}/?token=${me.apiKey}`
                axios.post(url, spelwijzerWerkvorm)
                    .then(response => {
                        spelwijzerWerkvorm.id = response.data;

                        me.spelwijzerWerkvormLookup[spelwijzerWerkvorm.bidocKoppeling].push(spelwijzerWerkvorm)
                        resolve(me.spelwijzerWerkvormLookup[spelwijzerWerkvorm.bidocKoppeling])
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteSpelwijzerWerkvorm = (spelwijzerWerkvorm) => {

        let me = this

        return new Promise((resolve, reject) => {
                const url = `${me.apiUrl}${me.SPELWIJZER_WERKVORM_TABLE}/${spelwijzerWerkvorm.id}?token=${me.apiKey}`

                axios.delete(url)
                    .then(() => {
                        me.spelwijzerWerkvormLookup[spelwijzerWerkvorm.bidocKoppeling] =
                            me.spelwijzerWerkvormLookup[spelwijzerWerkvorm.bidocKoppeling].filter(wv => wv.id !== spelwijzerWerkvorm.id)
                        resolve(me.spelwijzerWerkvormLookup[spelwijzerWerkvorm.bidocKoppeling])
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }
}
