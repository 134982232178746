import {convertObjectToClass} from "../util/ClassConvertor";
import {Event, RoosteringContext, RoosteringFilter} from "../class/roostering/Event";
import {Instelling} from "../class/roostering/Instelling";
import {ApplicationPath} from "../domain/path";
import {UserService} from "./UserService";
import {User} from "../class/user/User";

const {RRuleSet, rrulestr} = require('rrule')
const moment = require('moment')
const axios = require('axios')
const _ = require('lodash')


export class RoosteringService {

    private readonly EVENT_TABLE: string = 'event'
    private readonly BALIE_TABLE: string = 'balies'
    private readonly INSTELLINGEN_TABLE: string = 'instellingen'
    private readonly MYSQL_DATE_FORMAT: string = 'YYYY-MM-DD HH:mm:ss'

    apiUrl: string
    apiKey: string
    userService: UserService
    initialized: boolean = false
    eventsLookup: Object = {}
    roosteringEventLookup: Object = {}
    chatroosterEventLookup: Object = {}
    instellingen: Instelling[] = []
    instellingenLookup: Object
    roosteringTotalenLookup: Object
    chatRoosteringTotalenLookup: Object

    academiejaarStart: any
    academiejaarEinde: any

    stats: object

    constructor(apiUrl: string, apiKey: string, userService: UserService) {
        this.apiUrl = apiUrl
        this.apiKey = apiKey
        this.userService = userService
    }

    init = () => {

        let me = this

        return new Promise(function (resolve) {

            if (me.initialized) {
                resolve('initialized')
                return
            }

            const date = new Date()
            const now = moment(date)
            const currentMonth = now.month()
            let offsetToAugust = 7 - currentMonth
            if (offsetToAugust < 0) offsetToAugust += 12
            const endMoment = moment(now).add(offsetToAugust, 'months').date(1)
            if (endMoment < now) endMoment.add(1, 'year')
            //TIJDELIJK STARTEN OP 1 OKTOBER
            //const startMoment = moment(endMoment).add(-1, 'year')
            const startMoment = moment(endMoment).add(-307, 'day')
            me.academiejaarStart = startMoment
            me.academiejaarEinde = endMoment

            Promise.all([
                me.getAllEvents(RoosteringContext.OPENINGSUREN, RoosteringFilter.MEDIATHEEK),
                me.getAllEvents(RoosteringContext.CHATPERMANENTIE),
                me.getAllEvents(RoosteringContext.BALIE_BESCHIKBAARHEID, RoosteringFilter.MEDEWERKER),
                me.getAllEvents(RoosteringContext.BALIEROOSTER_SJABLOON, RoosteringFilter.MEDIATHEEK),
                me.getRoosteringTotalen(),
                me.getChatRoosteringTotalen(),
                me.getInstellingen()
            ]).then(() => {
                me.initialized = true
                resolve('initialized')
                me.createStats()
            })
        })

    }

    //=====================================================================================
    //=====================================================================================
    //     STATS
    //=====================================================================================
    //=====================================================================================

    createStats = (date?) => {
        const stats = {
            openingsurenTotalen: {},
            mediatheekPrestatiePercentageTotalen: {},
            chatOpeningsurenTotaal: 0,
            chatPecentageTotaal: 0,
            startMoment: null,
            endMoment: null,
            aantalWekenInPeriode: 0
        }
        let rruleSet, duration

        /*if (!date) date = new Date()
        const now = moment(date)
        const currentMonth = now.month()
        let offsetToAugust = 7 - currentMonth
        if (offsetToAugust < 0) offsetToAugust += 12*/
        const endMoment = this.academiejaarEinde //moment(now).add(offsetToAugust, 'months').date(1)
        //if (endMoment < now) endMoment.add(1, 'year')
        const startMoment = this.academiejaarStart //moment(endMoment).add(-1, 'year')

        stats.startMoment = startMoment
        stats.endMoment = endMoment

        //aantal werkweken
        const weeks: any = moment.duration((endMoment - startMoment))
        stats.aantalWekenInPeriode = weeks.asWeeks() / 52 * 44

        //console.log('STATS BETWEEN', startMoment.format('DD MM YYYY'), 'and', endMoment.format('DD MM YYYY'))

        //console.log('THIS', this)
        //TOTALEN OPENINGSUREN
        for (const eventsLookupElementElementKey in this.eventsLookup[RoosteringContext.OPENINGSUREN][RoosteringFilter.MEDIATHEEK]) {
            const events = this.eventsLookup[RoosteringContext.OPENINGSUREN][RoosteringFilter.MEDIATHEEK][eventsLookupElementElementKey]
                .filter(event => {
                    if (event.einddatum) {
                        return event.einddatum > startMoment.format(this.MYSQL_DATE_FORMAT) && event.start < endMoment.format(this.MYSQL_DATE_FORMAT)
                    }

                    return event.start < endMoment.format(this.MYSQL_DATE_FORMAT)
                })

            for (const event of events) {
                duration = moment(event.einde) - moment(event.start)
                rruleSet = this.createRRuleSetFromRawEvent(event)
                const eventsArray = rruleSet.between(startMoment.toDate(), endMoment.toDate())
                if (!stats.openingsurenTotalen[event.mediatheek]) stats.openingsurenTotalen[event.mediatheek] = 0
                stats.openingsurenTotalen[event.mediatheek] += (duration / 1000) * eventsArray.length
            }
        }

        //TOTALEN TE PRESTEREN
        for (const instellingenLookupKey in this.instellingenLookup) {
            const instelling = this.instellingenLookup[instellingenLookupKey]
            const medKey = instelling.ankerbalie !== '' ? instelling.ankerbalie : 'zonderAnkerBalie'
            if (!stats.mediatheekPrestatiePercentageTotalen[medKey]) stats.mediatheekPrestatiePercentageTotalen[medKey] = 0
            stats.mediatheekPrestatiePercentageTotalen[medKey] += instelling.baliePrestatiePercentage
        }

        //BEREKENEN VAN TE PRESTEREN UREN PER MEDEWERKER
        for (const instellingenLookupKey in this.instellingenLookup) {
            const instelling = this.instellingenLookup[instellingenLookupKey]
            const medKey = instelling.ankerbalie !== '' ? instelling.ankerbalie : 'zonderAnkerBalie'
            instelling.gepresteerdeUren = this.roosteringTotalenLookup[instelling.userId] ? parseInt(this.roosteringTotalenLookup[instelling.userId].duration) / 60 / 60 : 0
            instelling.tePresterenUren = (stats.openingsurenTotalen[medKey] / stats.mediatheekPrestatiePercentageTotalen[medKey] * instelling.baliePrestatiePercentage) / 60 / 60
            if (isNaN(instelling.tePresterenUren)) instelling.tePresterenUren = 0
            instelling.prestatieFactor = instelling.tePresterenUren > 0 ? instelling.gepresteerdeUren / instelling.tePresterenUren : 1
        }

        //SORTEREN VAN DE PRESTATIEFACTOR
        const groupedInstellingen = _.groupBy(_.orderBy(this.instellingen, "prestatieFactor"), 'ankerbalie')
        for (const groupedInstellingenKey in groupedInstellingen) {
            const group = groupedInstellingen[groupedInstellingenKey]
            group.forEach((instelling, index) => {
                instelling.prestatieFactorIndex = index
            })
        }

        //CHAT


        //TOTALEN CHAT OPENINGSUREN
        const events = this.eventsLookup[RoosteringContext.CHATPERMANENTIE]
            .filter(event => {
                if (event.einddatum) {
                    return event.einddatum > startMoment.format(this.MYSQL_DATE_FORMAT) && event.start < endMoment.format(this.MYSQL_DATE_FORMAT)
                }

                return event.start < endMoment.format(this.MYSQL_DATE_FORMAT)
            })

        for (const event of events) {
            duration = moment(event.einde) - moment(event.start)
            rruleSet = this.createRRuleSetFromRawEvent(event)
            const eventsArray = rruleSet.between(startMoment.toDate(), endMoment.toDate())
            let total = duration / 1000
            if (event.herhaal === 1) total *= eventsArray.length
            stats.chatOpeningsurenTotaal += total
        }

        stats.chatOpeningsurenTotaal *= 1

        //TOTALEN TE PRESTEREN
        for (const instellingenLookupKey in this.instellingenLookup) {
            const instelling = this.instellingenLookup[instellingenLookupKey]
            stats.chatPecentageTotaal += instelling.chatPrestatiePercentage
        }

        //BEREKENEN VAN TE PRESTEREN UREN PER MEDEWERKER
        for (const instellingenLookupKey in this.instellingenLookup) {
            const instelling = this.instellingenLookup[instellingenLookupKey]
            instelling.gepresteerdeChatUren = this.chatRoosteringTotalenLookup[instelling.userId] ? parseInt(this.chatRoosteringTotalenLookup[instelling.userId].duration) / 60 / 60 : 0
            instelling.tePresterenChatUren = ((stats.chatOpeningsurenTotaal / stats.chatPecentageTotaal) * instelling.chatPrestatiePercentage) / 60 / 60
            instelling.gemiddeldPerWeek = instelling.tePresterenChatUren / stats.aantalWekenInPeriode
            if (isNaN(instelling.tePresterenChatUren)) instelling.tePresterenChatUren = 0
            instelling.chatPrestatieFactor = instelling.tePresterenChatUren > 0 ? instelling.gepresteerdeChatUren / instelling.tePresterenChatUren : 1
        }

        //SORTEREN VAN DE PRESTATIEFACTOR
        /*const groupedInstellingen = _.groupBy(_.orderBy(this.instellingen, "prestatieFactor"), 'ankerbalie')
        for (const groupedInstellingenKey in groupedInstellingen) {
            const group = groupedInstellingen[groupedInstellingenKey]
            group.forEach((instelling, index) => {
                instelling.prestatieFactorIndex = index
            })
        }*/

        this.stats = stats

        //console.log('STATZ', stats, startMoment, endMoment, this.chatRoosteringTotalenLookup, this.instellingenLookup)
    }

    createRRuleSetFromRawEvent = (rawEvent) => {
        const rruleSet = new RRuleSet()
        rruleSet.rrule(rrulestr(rawEvent.rrule))

        const exdates = rawEvent.exdates.split(',')
        for (const exdate of exdates) {
            let m = moment(exdate)
            if (m.isValid())
                rruleSet.exdate(m.toDate())
        }

        return rruleSet
    }

    //=====================================================================================
    //=====================================================================================
    //     BEZOEKEN
    //=====================================================================================
    //=====================================================================================

    getBezoeken = (mediatheek: string = null, start: string = null) => {
        let url = ApplicationPath.bezoekenApiUrl

        if (mediatheek) url += mediatheek

        return new Promise((resolve, reject) => {

            axios.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                })
        })
    }

    createBezoek = (event) => {
        let url = ApplicationPath.bezoekenApiUrl + '/'

        let me = this

        return new Promise((resolve, reject) => {
                axios.post(url, event)
                    .then(response => {
                        event.id = response.data.data.id
                        resolve(event)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    updateBezoek = (event) => {
        let url = ApplicationPath.bezoekenApiUrl + event.id + '/'
        let me = this

        return new Promise((resolve, reject) => {
                resolve(event)
                axios.put(url, event)
                    .then(response => {
                        resolve(response)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteBezoek = (event) => {
        let url = ApplicationPath.bezoekenApiUrl + event.id + '/'
        let me = this

        return new Promise((resolve, reject) => {
                axios.delete(url, event)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    //=====================================================================================
    //=====================================================================================
    //     EVENTS
    //=====================================================================================
    //=====================================================================================

    getEvents = (context, filter: any, _van, _tot) => {

        let me = this

        return new Promise(function (resolve, reject) {

            //het kan zijn dat er geen filterObjectKey is!!
            if (filter) {

                if (!me.eventsLookup[context]) me.eventsLookup[context] = {}
                if (!me.eventsLookup[context][filter.key]) me.eventsLookup[context][filter.key] = {}
                if (me.eventsLookup[context][filter.key][filter.value]) {
                    resolve({events: me.eventsLookup[context][filter.key][filter.value]})
                    return
                }
            } else {
                if (me.eventsLookup[context]) {
                    resolve({events: me.eventsLookup[context]})
                    return
                }
            }

            let url = `${me.apiUrl}${me.EVENT_TABLE}?transform=1&token=${me.apiKey}&filter[]=context,eq,${context}`
            if (filter) url += `&filter[]=${filter.key},eq,${filter.value}`

            axios.get(url)
                .then(response => {
                    let rawEvent = response.data[me.EVENT_TABLE];

                    if (filter) {
                        if (!me.eventsLookup[context][filter.key][filter.value]) {
                            me.eventsLookup[context][filter.key][filter.value] = rawEvent.map((rq: any) => convertObjectToClass(rq, 'Event'))
                        }
                        resolve({events: me.eventsLookup[context][filter.key][filter.value]})
                    } else {
                        me.eventsLookup[context] = rawEvent.map((rq: any) => convertObjectToClass(rq, 'Event'))
                        resolve({events: me.eventsLookup[context]})
                    }

                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    getAllEvents = (context: string, filter?: string) => {

        let me = this

        return new Promise(function (resolve, reject) {

            let url = `${me.apiUrl}${me.EVENT_TABLE}?transform=1&token=${me.apiKey}&filter[]=context,eq,${context}`

            axios.get(url)
                .then(response => {
                    let rawEvents = response.data[me.EVENT_TABLE];
                    for (const rawEvent of rawEvents) {

                        let event = convertObjectToClass(rawEvent, 'Event')
                        if (filter) {
                            if (!me.eventsLookup[context]) me.eventsLookup[context] = {}
                            if (!me.eventsLookup[context][filter]) me.eventsLookup[context][filter] = {}
                            if (!me.eventsLookup[context][filter][event[filter]]) me.eventsLookup[context][filter][event[filter]] = []

                            me.eventsLookup[context][filter][event[filter]].push(event)
                        } else {

                            if (!me.eventsLookup[context]) me.eventsLookup[context] = []
                            me.eventsLookup[context].push(event)
                        }

                    }

                    resolve({events: me.eventsLookup[context]})
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    updateEvent = (event: Event) => {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.EVENT_TABLE}/${event.id}?&token=${me.apiKey}`;
                axios.put(url, event)
                    .then(response => {
                        resolve(response)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    createEvent = (event: Event, filterKey?: string) => {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.EVENT_TABLE}/?token=${me.apiKey}`
                axios.post(url, event)
                    .then(response => {
                        event.id = response.data
                        //{key: 'medewerker', value: userId}

                        if (filterKey) {
                            if (!me.eventsLookup[event.context]) me.eventsLookup[event.context] = {}
                            if (!me.eventsLookup[event.context][filterKey]) me.eventsLookup[event.context][filterKey] = {}
                            if (!me.eventsLookup[event.context][filterKey][event[filterKey]]) me.eventsLookup[event.context][filterKey][event[filterKey]] = []
                            me.eventsLookup[event.context][filterKey][event[filterKey]].push(event)
                        } else {
                            if (!me.eventsLookup[event.context]) me.eventsLookup[event.context] = []
                            me.eventsLookup[event.context].push(event)
                        }
                        resolve(event)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteEvent = (event: Event, filterKey?) => {

        let me = this

        return new Promise((resolve, reject) => {
                const url = `${me.apiUrl}${me.EVENT_TABLE}/${event.id}?token=${me.apiKey}`
                axios.delete(url, event)
                    .then(response => {
                        if (filterKey) {
                            me.eventsLookup[event.context][filterKey][event[filterKey]] = me.eventsLookup[event.context][filterKey][event[filterKey]].filter(bs => bs.id !== event.id)
                        } else {
                            me.eventsLookup[event.context] = me.eventsLookup[event.context].filter(bs => bs.id !== event.id)
                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }


    //=====================================================================================
    //=====================================================================================
    //     ROOSTERING EVENTS
    //=====================================================================================
    //=====================================================================================

    getRoosteringEvents = (date, forceRefetch: boolean = false) => {

        let me = this

        const start = moment(date).format('YYYY-MM-DD')
        const einde = moment(start).add(1, 'day').format('YYYY-MM-DD')

        if (forceRefetch) me.roosteringEventLookup[start] = null

        return new Promise(function (resolve, reject) {

            if (me.roosteringEventLookup[start]) {
                resolve({events: me.roosteringEventLookup[start]})
                return
            }
            let url = `${me.apiUrl}${me.EVENT_TABLE}?filter[]=start,gt,${start}&filter[]=einde,lt,${einde}&transform=1&filter[]=context,eq,${RoosteringContext.BALIEROOSTER}&token=${me.apiKey}`

            axios.get(url)
                .then(response => {
                    let rawEvent = response.data[me.EVENT_TABLE];
                    if (!me.roosteringEventLookup[start]) {
                        me.roosteringEventLookup[start] = rawEvent.map((rq: any) => convertObjectToClass(rq, 'Event'))
                    }
                    resolve({url, events: me.roosteringEventLookup[start], lookup: me.roosteringEventLookup})
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    createRoosteringEvent = (event: Event, date) => {

        let me = this

        const dag = moment(date).format('YYYY-MM-DD')

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.EVENT_TABLE}/?token=${me.apiKey}`
                axios.post(url, event)
                    .then(response => {
                        event.id = response.data
                        if (!me.roosteringEventLookup[dag]) me.roosteringEventLookup[dag] = []
                        me.roosteringEventLookup[dag].push(event)
                        resolve(event)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteRoosteringEvents = (events: Event[], date) => {

        let me = this

        const dag = moment(date).format('YYYY-MM-DD')

        const ids = events.map(event => event.id).join(',')

        events.forEach(event => me.roosteringEventLookup[dag] = me.roosteringEventLookup[dag].filter(bs => bs.id !== event.id))

        return new Promise((resolve, reject) => {
                const url = `${me.apiUrl}${me.EVENT_TABLE}/${ids}?token=${me.apiKey}`
                axios.delete(url)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    getOngedekteRoosteringEvents = (beschikbaarheid: Event) => {

        let me = this

        //we halen enkel events op die in de toekomst liggen
        const start = moment().format('YYYY-MM-DD')

        return new Promise(function (resolve, reject) {

            let url = `${me.apiUrl}${me.EVENT_TABLE}?transform=1&token=${me.apiKey}&filter[]=context,eq,${RoosteringContext.BALIEROOSTER}&filter[]=start,gte,${start}&filter[]=beschikbaarheid,eq,${beschikbaarheid.id}`

            axios.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

//=====================================================================================
    //=====================================================================================
    //     CHATROOSTER EVENTS
    //=====================================================================================
    //=====================================================================================

    getChatroosterEvents = (date, forceRefetch: boolean = false) => {

        let me = this

        const start = moment(date).format('YYYY-MM-DD')
        const einde = moment(start).add(1, 'day').format('YYYY-MM-DD')

        if (forceRefetch) me.chatroosterEventLookup[start] = null

        return new Promise(function (resolve, reject) {

            if (me.chatroosterEventLookup[start]) {
                resolve({events: me.chatroosterEventLookup[start]})
                return
            }

            let url = `${me.apiUrl}${me.EVENT_TABLE}?filter[]=start,gt,${start}&filter[]=einde,lt,${einde}&transform=1&filter[]=context,eq,${RoosteringContext.CHATROOSTER}&token=${me.apiKey}`

            axios.get(url)
                .then(response => {
                    let rawEvent = response.data[me.EVENT_TABLE];
                    if (!me.chatroosterEventLookup[start]) {
                        me.chatroosterEventLookup[start] = rawEvent.map((rq: any) => convertObjectToClass(rq, 'Event'))
                    }
                    resolve({url, events: me.chatroosterEventLookup[start], lookup: me.chatroosterEventLookup})
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    createChatroosterEvent = (event: Event, date) => {

        let me = this

        const dag = moment(date).format('YYYY-MM-DD')

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.EVENT_TABLE}/?token=${me.apiKey}`
                axios.post(url, event)
                    .then(response => {
                        event.id = response.data
                        if (!me.chatroosterEventLookup[dag]) me.chatroosterEventLookup[dag] = []
                        me.chatroosterEventLookup[dag].push(event)
                        resolve(event)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteChatroosterEvents = (events: Event[], date) => {

        let me = this

        const dag = moment(date).format('YYYY-MM-DD')

        const ids = events.map(event => event.id).join(',')

        events.forEach(event => me.chatroosterEventLookup[dag] = me.chatroosterEventLookup[dag].filter(bs => bs.id !== event.id))

        return new Promise((resolve, reject) => {
                const url = `${me.apiUrl}${me.EVENT_TABLE}/${ids}?token=${me.apiKey}`
                axios.delete(url)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    getOngedekteChatroosterEvents = (beschikbaarheid: Event) => {

        let me = this

        //we halen enkel events op die in de toekomst liggen
        const start = moment().format('YYYY-MM-DD')

        return new Promise(function (resolve, reject) {

            let url = `${me.apiUrl}${me.EVENT_TABLE}?transform=1&token=${me.apiKey}&filter[]=context,eq,${RoosteringContext.CHATROOSTER}&filter[]=start,gte,${start}&filter[]=beschikbaarheid,eq,${beschikbaarheid.id}`

            axios.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    //=====================================================================================
    //=====================================================================================
    //     INSTELLINGEN
    //=====================================================================================
    //=====================================================================================

    getInstellingen = () => {

        let me = this

        return new Promise(function (resolve, reject) {

            if (me.instellingenLookup) {
                resolve(me.instellingenLookup)
            }

            let url = `${me.apiUrl}${me.INSTELLINGEN_TABLE}?transform=1&token=${me.apiKey}`

            axios.get(url)
                .then(response => {
                    me.instellingen = response.data[me.INSTELLINGEN_TABLE]
                        .map(ri => convertObjectToClass(ri, 'Instelling'))
                        .filter(inst => me.userService.userIdLookup[inst.userId] !== undefined)
                    //enkel instellingen voor bestaande users gebruiken

                    me.instellingenLookup = _.keyBy(me.instellingen, 'userId')

                    let geenInstelling = [], newInstelling: Instelling

                    for (const user of me.userService.users) {
                        if (!me.instellingenLookup[user['id']]) {
                            newInstelling = new Instelling()
                            newInstelling.mail = user['mail']
                            newInstelling.userId = user['id']
                            geenInstelling.push(me.createInstelling(newInstelling))
                        }
                    }

                    if (geenInstelling.length > 0) {
                        Promise.all(geenInstelling).then(() => {
                            resolve(me.instellingenLookup)
                        })

                    } else {
                        resolve(me.instellingenLookup)
                    }
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    updateInstelling = (instelling: Instelling) => {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.INSTELLINGEN_TABLE}/${instelling.id}?&token=${me.apiKey}`;
                axios.put(url, instelling)
                    .then(response => {
                        resolve(response)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    createInstelling = (instelling: any) => {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.INSTELLINGEN_TABLE}/?token=${me.apiKey}`
                axios.post(url, instelling)
                    .then(response => {
                        instelling.id = response.data
                        me.instellingenLookup[instelling.userId] = instelling
                        resolve(me.instellingenLookup)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    getRoosteringTotalen = () => {

        let me = this

        //total duration
        const url = "https://lib.arteveldehogeschool.be/mediatheken/bo-server/service/roosteringService.php"

        const startDatum = me.academiejaarStart.format("YYYY-MM-DD") //'2019-08-15'

        let params = new URLSearchParams();
        params.append('startDatum', startDatum);
        params.append('function', 'getRoosteringTotalen');

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const getUrl = url + `?function=getRoosteringTotalen&startDatum=${startDatum}`
        return new Promise((resolve, reject) => {
                axios.get(getUrl)
                    .then(response => {
                        me.roosteringTotalenLookup = _.keyBy(response.data, 'medewerker')
                        resolve(me.roosteringTotalenLookup)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
        /*return new Promise((resolve, reject) => {
                axios.post(url, params, config)
                    .then(response => {
                        me.roosteringTotalenLookup = _.keyBy(response.data, 'medewerker')
                        resolve(me.roosteringTotalenLookup)
                    })
                    .catch((error) => {
                        console.log(error);
                        resolve(null)
                        //reject(error);
                    });
            }
        )*/
    }

    getChatRoosteringTotalen = () => {

        let me = this

        //total duration
        const url = "https://lib.arteveldehogeschool.be/mediatheken/bo-server/service/roosteringService.php"

        const startDatum = me.academiejaarStart.format("YYYY-MM-DD") //'2019-08-15'

        let params = new URLSearchParams();
        params.append('startDatum', startDatum);
        params.append('function', 'getChatRoosteringTotalen');

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const getUrl = url + `?function=getChatRoosteringTotalen&startDatum=${startDatum}`

        return new Promise((resolve, reject) => {
                axios.get(getUrl)
                    .then(response => {
                        me.chatRoosteringTotalenLookup = _.keyBy(response.data, 'medewerker')
                        resolve(me.chatRoosteringTotalenLookup)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )

        /*return new Promise((resolve, reject) => {
                axios.post(url, params, config)
                    .then(response => {
                        me.chatRoosteringTotalenLookup = _.keyBy(response.data, 'medewerker')
                        resolve(me.chatRoosteringTotalenLookup)
                    })
                    .catch((error) => {
                        console.log(error);
                        resolve(null)
                        //reject(error);
                    });
            }
        )*/
    }
}
