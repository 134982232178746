import React, {Component, Fragment} from 'react'
import {Segment, Button} from 'semantic-ui-react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'

class DeeltitelsRenderer extends Component {

    state = {deeltitelsVisible: false}

    render() {
        const {deeltitelsVisible} = this.state
        const {zoekresultaat, localeManager, label} = this.props

        if (!zoekresultaat.deeltitels) return null

        
        return (
            <AbstractZoekresultaatRenderer label={localeManager.getString(label)}>

                <Segment>
                    {!deeltitelsVisible &&
                    <Button onClick={() => this.setState({deeltitelsVisible: true})}>
                        {localeManager.getString('DEELTITELS_INFO').replace('%num%', zoekresultaat.deeltitels.length)}
                    </Button>
                    }


                    {deeltitelsVisible &&
                    <Fragment>
                        <Button style={{marginBottom: '20px'}}
                                onClick={() => this.setState({deeltitelsVisible: false})}>
                            {localeManager.getString('SLUIT')}
                        </Button>

                        {zoekresultaat.deeltitels.map((deeltitel, index) => {
                            const link = `//lib.arteveldehogeschool.be/mediatheken/live/ws/?dl=search&id=${deeltitel.Titel_NR}&src=${zoekresultaat.bron}`

                            return <div><a href={link} target='_blank' rel='noopener noreferrer'>{deeltitel.Titel}</a></div>
                        })}

                        <Button style={{marginTop: '20px'}}
                                onClick={() => this.setState({deeltitelsVisible: false})}>
                            {localeManager.getString('SLUIT')}
                        </Button>

                    </Fragment>
                    }
                </Segment>

            </AbstractZoekresultaatRenderer>
        )
    }
}

export default DeeltitelsRenderer