import {SoftwareColor} from "../class/Admin";

export const releaseMail = ({release, changelogWrappers, software}) => {

    /*
    * export const MEDIATHEEK_ROOT = "https://lib.arteveldehogeschool.be/mediatheken/"
export const logoRoot = MEDIATHEEK_ROOT + "root/mediatheek-assets/logos/"
export const icoonMediatheek = logoRoot + "mediatheek/icoon-mediatheek-60.png"
*/
    console.log('WRAPPERS', changelogWrappers)

    /*const changelogContent = changelogWrappers.map(wrapper => {
        return `<h3>${wrapper.label}</h3>`
    })*/

    return (
        `
            <html>
                <head>
                    <meta http-equiv="content-type" content="text/html; charset=UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1">
                    <style>
                        body {
		                    text-align: center;
	                    }
	                    
	                    .main {
		                    background-color: lightgray;
		                    padding: 20px;
		                    width: 600px;
		                    display: inline-block;
	                    }
                        .label {
                            padding: 6px;
                            margin-right: 5px;
                            font-family: Arial;
                            border-radius: 5px;
                            background-color: green;
                        }
                        .software-container {
	                        background-color: white;
	                        display: inline-block;
	                        padding: 20px;
	                        margin: 20px;
                        }
                        .module {
	                        background-color: #d9f0f7;
	                        text-align: left;
	                        padding: 15px;
	                        margin-bottom: 10px
                        }
                        .signature {
                            
                        }

                    </style>
                </head>
                <body>
                
                    <div class="main">
		                <div><img src="https://lib.arteveldehogeschool.be/mediatheken/root/mediatheek-assets/logos/mediatheek/icoon-mediatheek-60.png"/></div>
		                
                        <p>Beste mediatheek medewerker</p>
                        <p>Er is een nieuwe versie beschikbaar voor volgende software:</p>
                        <div class="software-container">
                            <span class="label" style="background-color: ${SoftwareColor[software.code.toUpperCase()]};color: white">${software.code} </span>
                            <span class="label" style="background-color: lightgray">Versie ${release.versie}</span>
                        </div>
                        
                        <div>${release.comment}</div>
                        
                        <h3>Wat is er veranderd:</h3>
                        
                       
                        ${changelogWrappers.map(wrapper => {
                            return `<div class="module">
                                <h4>${wrapper.label}</h4>
                                <ul>
                                ${wrapper.children.map(changelog => `<li>${changelog.content}</li>`)}
                                </ul>
                            </div>`
                        })}
                       
                        <div class="signature">
                            <h5>Groeten van Dany en Lennert</h5>
                        </div>
                    </div>
                    
                </body>
            </html>
            `
    )
}
